import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import PurchasedContext from 'context/purchased/PurchasedContext';
import UserContext from 'context/user/UserContext';
import PublishedContext from 'context/published/PublishedContext';
import { formatDateFromMiliseconds, formatTimeFromMiliseconds } from 'helpers';

export const ViewTheme = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { themeId } = useParams();

  const { savePurchasedQuestionnaire } = useContext(PurchasedContext);
  const { userState, getUserInfo } = useContext(UserContext);
  const { getSinglePublishedQuestionnaire } = useContext(PublishedContext);
  const [themeData, setThemeData] = useState(state);

  const showPurchaseButton = useMemo(
    () =>
      userState?.user &&
      userState.user['cognito:username'] !== themeData?.userId,
    [userState, themeData]
  );

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (!state) {
      const creationTime = themeId.slice(5);
      getSinglePublishedQuestionnaire(parseInt(creationTime)).then((data) => {
        setThemeData(data);
      });
    } else {
      setThemeData(state);
    }
  }, [state, themeId]);

  const onBack = () => {
    navigate('/questionnaires');
  };

  const onPurchase = async () => {
    await savePurchasedQuestionnaire(themeData.infodata);
    navigate('/questionnaires');
  };

  return (
    <Box sx={{ p: '30px 60px' }}>
      <Typography variant="h5" sx={{ textAlign: 'center', mb: 5 }}>
        {themeData?.infodata?.themeName || 'Theme Details'}
      </Typography>
      <Box
        sx={{
          width: '500px',
          maxWidth: '80%',
          mx: 'auto',
        }}
      >
        <Box sx={{ mt: 6, mb: 9 }}>
          <Grid container rowGap={4}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle-2"
                sx={{ color: 'gray', fontSize: '14px' }}
              >
                Description
              </Typography>
              <Typography sx={{ mt: 0.5 }}>
                {themeData?.infodata?.themeDescription || 'No description'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="subtitle-2"
                sx={{ color: 'gray', fontSize: '14px' }}
              >
                Theme Type
              </Typography>
              <Typography sx={{ mt: 0.5 }}>
                {themeData?.themeType || ''}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="subtitle-2"
                sx={{ color: 'gray', fontSize: '14px' }}
              >
                Theme cost
              </Typography>
              <Typography sx={{ mt: 0.5 }}>
                {themeData?.infodata?.themeCost > 0 ? (
                  <> {themeData?.infodata?.themeCost} &#x20AC;</>
                ) : (
                  'Free'
                )}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="subtitle-2"
                sx={{ color: 'gray', fontSize: '14px' }}
              >
                Theme URL
              </Typography>
              <Typography sx={{ mt: 0.5 }}>
                <a href={themeData?.infodata?.themeUrl || ''} target="_blank">
                  {themeData?.infodata?.themeUrl || ''}
                </a>
              </Typography>
            </Grid>

            {themeData?.themeType === 'Timed Exam' && (
              <Grid item xs={6}>
                <Typography
                  variant="subtitle-2"
                  sx={{ color: 'gray', fontSize: '14px' }}
                >
                  Time limit (in minutes)
                </Typography>
                <Typography sx={{ mt: 0.5 }}>
                  {themeData?.infodata?.themeTimeLimit || 0}
                </Typography>
              </Grid>
            )}

            <Grid item xs={6}>
              <Typography
                variant="subtitle-2"
                sx={{ color: 'gray', fontSize: '14px' }}
              >
                Is Premium
              </Typography>
              <Typography sx={{ mt: 0.5 }}>
                {themeData?.infodata?.isPremium ? 'Yes' : 'No'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="subtitle-2"
                sx={{ color: 'gray', fontSize: '14px' }}
              >
                Number of questions
              </Typography>
              <Typography sx={{ mt: 0.5 }}>
                {themeData?.infodata?.questionsList?.length || 0} questions
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="subtitle-2"
                sx={{ color: 'gray', fontSize: '14px' }}
              >
                Version Number
              </Typography>
              <Typography sx={{ mt: 0.5 }}>
                {themeData?.infodata?.versionNo || 0}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="subtitle-2"
                sx={{ color: 'gray', fontSize: '14px' }}
              >
                Last updated on
              </Typography>
              <Typography sx={{ mt: 0.5 }}>
                {formatDateFromMiliseconds(parseInt(themeData?.updatedTime)) +
                  ', ' +
                  formatTimeFromMiliseconds(parseInt(themeData?.updatedTime))}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button type="button" onClick={onBack} variant="outlined">
            Back
          </Button>

          {showPurchaseButton && (
            <Button type="button" onClick={onPurchase} variant="contained">
              Purchase
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
