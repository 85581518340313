import React, { useContext, useEffect } from 'react';
import CoreLayout from '../layouts/CoreLayout';
import ThemeContext from 'context/theme/ThemeContext';
import UserContext from 'context/user/UserContext';

export const NotFound404 = () => {
  const { userGroup } = useContext(UserContext);
  const { showAlert, closeAlert } = useContext(ThemeContext);

  useEffect(() => {
    if (userGroup === 'noUserGroup') {
      //TODO: check if this logic should be here, only on NotFound404 page, or it should be inside the core layout component?
      showAlert(
        'User is not a part of any user group. Please contact the administrator.'
      );
    } else {
      closeAlert();
    }
  }, [userGroup]);

  return (
    <CoreLayout>
      <h1>Page not found</h1>
    </CoreLayout>
  );
};
