// Variables for the Reducer

export const SET_ELEMENT_VALUE = 'PURCHASED_SET_ELEMENT_VALUE';
export const LIST_PURCHASED_QUESTIONNAIRES = 'LIST_PURCHASED_QUESTIONNAIRES';
export const SET_QUESTIONNAIRES_WITH_PROGRESS =
  'SET_QUESTIONNAIRES_WITH_PROGRESS';

export const SAVE_QUESTIONNAIRE_PROGRESS_LOADING =
  'SAVE_QUESTIONNAIRE_PROGRESS_LOADING';
export const SAVE_QUESTIONNAIRE_PROGRESS_SUCCESS =
  'SAVE_QUESTIONNAIRE_PROGRESS_SUCCESS';
export const SAVE_QUESTIONNAIRE_PROGRESS_ERROR =
  'SAVE_QUESTIONNAIRE_PROGRESS_ERROR';

export const GET_ALL_PURCHASED_QUESTIONNAIRES_LOADING =
  'GET_ALL_PURCHASED_QUESTIONNAIRES_LOADING';
export const GET_ALL_PURCHASED_QUESTIONNAIRES_ERROR =
  'GET_ALL_PURCHASED_QUESTIONNAIRES_ERROR';

export const SAVE_PURCHASED_QUESTIONNAIRE_LOADING =
  'SAVE_PURCHASED_QUESTIONNAIRE_LOADING';
export const SAVE_PURCHASED_QUESTIONNAIRE_SUCCESS =
  'SAVE_PURCHASED_QUESTIONNAIRE_SUCCESS';
export const SAVE_PURCHASED_QUESTIONNAIRE_ERROR =
  'SAVE_PURCHASED_QUESTIONNAIRE_ERROR';

export const EDIT_PURCHASED_QUESTIONNAIRE_LOADING =
  'EDIT_PURCHASED_QUESTIONNAIRE_LOADING';
export const EDIT_PURCHASED_QUESTIONNAIRE_SUCCESS =
  'EDIT_PURCHASED_QUESTIONNAIRE_SUCCESS';
export const EDIT_PURCHASED_QUESTIONNAIRE_ERROR =
  'EDIT_PURCHASED_QUESTIONNAIRE_ERROR';

export const DELETE_PURCHASED_QUESTIONNAIRE_LOADING =
  'DELETE_PURCHASED_QUESTIONNAIRE_LOADING';
export const DELETE_PURCHASED_QUESTIONNAIRE_SUCCESS =
  'DELETE_PURCHASED_QUESTIONNAIRE_SUCCESS';
export const DELETE_PURCHASED_QUESTIONNAIRE_ERROR =
  'DELETE_PURCHASED_QUESTIONNAIRE_ERROR';

export const GET_PURCHASED_QUESTIONNAIRE_LOADING =
  'GET_PURCHASED_QUESTIONNAIRE_LOADING';
export const GET_PURCHASED_QUESTIONNAIRE_SUCCESS =
  'GET_PURCHASED_QUESTIONNAIRE_SUCCESS';
export const GET_PURCHASED_QUESTIONNAIRE_ERROR =
  'GET_PURCHASED_QUESTIONNAIRE_ERROR';
