import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Chart } from 'chart.js';
import { Button, Grid, Typography } from '@mui/material';

export const Results = () => {
  const { state: data } = useLocation();
  const pieChartRef = useRef();
  const lineChartRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const myPieChartRef = pieChartRef.current.getContext('2d');
    const myLineChartRef = lineChartRef.current.getContext('2d');

    const pastAttempts = data?.pastAttempts || [];
    const labels = pastAttempts.reduce((acc, itm) => {
      acc.push(itm.lastUpdateDate);
      return acc;
    }, []);
    const pastResults = pastAttempts.reduce((acc, itm) => {
      acc.push(itm.result);
      return acc;
    }, []);

    new Chart(myPieChartRef, {
      type: 'pie',
      data: {
        labels: ['Correct', 'Wrong'],
        datasets: [
          {
            label: 'Number of questions',
            backgroundColor: ['#BCE29E', '#FF8787'],
            data: [data?.correctQuestions || 0, data?.wrongQuestions || 0],
          },
        ],
      },
      options: {
        // responsive: true,
        title: {
          display: true,
          text: 'Correct vs incorrect answers for the selected quiz',
        },
      },
    });

    new Chart(myLineChartRef, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Previous attempts',
            data: pastResults,
            borderColor: '#FF8787',
            // backgroundColor: 'lightblue',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        title: {
          display: true,
          text: 'Previous attempts for the selected quiz this year',
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }, [data]);

  return (
    <>
      <Typography sx={{ textAlign: 'center', my: 4 }} variant="h4">
        Results
      </Typography>

      <Button
        variant="outlined"
        onClick={() => navigate('/questionnaires?selectedTab=Purchased')}
        sx={{
          ml: 6,
          mb: 2,
          fontSize: '10px',
          color: 'black',
          border: '1px solid black',
          '&:hover': {
            border: '1px solid black',
          },
        }}
      >
        Exit
      </Button>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <canvas ref={pieChartRef} width="700" height="500"></canvas>
        </Grid>
        <Grid item xs={12} sm={6}>
          <canvas ref={lineChartRef} width="700" height="500"></canvas>
        </Grid>
      </Grid>
    </>
  );
};
