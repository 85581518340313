import React, { useReducer } from 'react';
import ThemeContext from './ThemeContext';
import ThemeReducer from './ThemeReducer';
import { SET_THEME, SHOW_ALERT, CLOSE_ALERT } from './types';

// Add all theme options here
export const themeOptions = [
  { name: 'Default', value: 'default' },
  { name: 'Dark', value: 'dark' },
  { name: 'Blue', value: 'blue' },
];

export let themeInitialState = {
  name: localStorage.getItem('declarationClientTheme') || 'default', // TODO: change name of the localStorage item
  shouldShowAlert: false,
  alertMessage: null,
};

// Load the initial state from localStorage if it exists
if (localStorage.getItem('theme')) {
  const theme = localStorage.getItem('theme');
  themeInitialState = { ...JSON.parse(theme) };
}

const ThemeState = (props) => {
  const [state, dispatch] = useReducer(ThemeReducer, themeInitialState);

  // Add the currently selected theme to the end of a className string
  const withTheme = (className) => {
    if (state.name === 'default') {
      return `${className}`;
    } else {
      return `${className} ${className}-${state.name}`;
    }
  };

  const setTheme = (themeName) => {
    localStorage.setItem('declarationClientTheme', themeName); // TODO: change name of the localStorage item
    // dispatch action to the reducer and update the state accordingly
    dispatch({ type: SET_THEME, payload: themeName });
  };

  const showAlert = (alertMessage) => {
    dispatch({ type: SHOW_ALERT, payload: alertMessage });
  };

  const closeAlert = () => {
    dispatch({ type: CLOSE_ALERT });
  };

  return (
    <ThemeContext.Provider
      value={{ theme: state, setTheme, withTheme, showAlert, closeAlert }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeState;
