// Variables for the Reducer

export const SET_ELEMENT_VALUE = 'DRAFTS_SET_ELEMENT_VALUE';
export const LIST_DRAFTS_QUESTIONNAIRES = 'LIST_DRAFTS_QUESTIONNAIRES';

export const SET_SELECTED_DRAFT_QUESTIONNAIRE =
  'SET_SELECTED_DRAFT_QUESTIONNAIRE';

export const GET_ALL_DRAFTS_QUESTIONNAIRES_LOADING =
  'GET_ALL_DRAFTS_QUESTIONNAIRES_LOADING';
export const GET_ALL_DRAFTS_QUESTIONNAIRES_ERROR =
  'GET_ALL_DRAFTS_QUESTIONNAIRES_ERROR';

export const SAVE_DRAFTS_QUESTIONNAIRE_LOADING =
  'SAVE_DRAFTS_QUESTIONNAIRE_LOADING';
export const SAVE_DRAFTS_QUESTIONNAIRE_SUCCESS =
  'SAVE_DRAFTS_QUESTIONNAIRE_SUCCESS';
export const SAVE_DRAFTS_QUESTIONNAIRE_ERROR =
  'SAVE_DRAFTS_QUESTIONNAIRE_ERROR';

export const EDIT_DRAFTS_QUESTIONNAIRE_LOADING =
  'EDIT_DRAFTS_QUESTIONNAIRE_LOADING';
export const EDIT_DRAFTS_QUESTIONNAIRE_SUCCESS =
  'EDIT_DRAFTS_QUESTIONNAIRE_SUCCESS';
export const EDIT_DRAFTS_QUESTIONNAIRE_ERROR =
  'EDIT_DRAFTS_QUESTIONNAIRE_ERROR';

export const DELETE_DRAFTS_QUESTIONNAIRE_LOADING =
  'DELETE_DRAFTS_QUESTIONNAIRE_LOADING';
export const DELETE_DRAFTS_QUESTIONNAIRE_SUCCESS =
  'DELETE_DRAFTS_QUESTIONNAIRE_SUCCESS';
export const DELETE_DRAFTS_QUESTIONNAIRE_ERROR =
  'DELETE_DRAFTS_QUESTIONNAIRE_ERROR';

export const GET_DRAFTS_QUESTIONNAIRE_LOADING =
  'GET_DRAFTS_QUESTIONNAIRE_LOADING';
export const GET_DRAFTS_QUESTIONNAIRE_SUCCESS =
  'GET_DRAFTS_QUESTIONNAIRE_SUCCESS';
export const GET_DRAFTS_QUESTIONNAIRE_ERROR = 'GET_DRAFTS_QUESTIONNAIRE_ERROR';
