import { useReducer } from 'react';
import {
  deletePublished,
  editPublished,
  getAll,
  getSinglePublished,
  savePublished,
  getAllActive,
} from '../../api/published';
import PublishedContext from './PublishedContext';
import publishedReducer from './PublishedReducer';
import {
  SET_ELEMENT_VALUE,
  LIST_PUBLISHED_QUESTIONNAIRES,
  GET_ALL_PUBLISHED_QUESTIONNAIRES_LOADING,
  GET_ALL_PUBLISHED_QUESTIONNAIRES_ERROR,
  SAVE_PUBLISHED_QUESTIONNAIRE_LOADING,
  SAVE_PUBLISHED_QUESTIONNAIRE_ERROR,
  SAVE_PUBLISHED_QUESTIONNAIRE_SUCCESS,
  EDIT_PUBLISHED_QUESTIONNAIRE_ERROR,
  EDIT_PUBLISHED_QUESTIONNAIRE_LOADING,
  EDIT_PUBLISHED_QUESTIONNAIRE_SUCCESS,
  DELETE_PUBLISHED_QUESTIONNAIRE_LOADING,
  DELETE_PUBLISHED_QUESTIONNAIRE_SUCCESS,
  DELETE_PUBLISHED_QUESTIONNAIRE_ERROR,
  GET_PUBLISHED_QUESTIONNAIRE_LOADING,
  GET_PUBLISHED_QUESTIONNAIRE_SUCCESS,
  GET_PUBLISHED_QUESTIONNAIRE_ERROR,
  GET_ACTIVE_QUESTIONNAIRES_LOADING,
  GET_ACTIVE_QUESTIONNAIRES_SUCCESS,
  GET_ACTIVE_QUESTIONNAIRES_ERROR,
} from './types';

export const initialState = {
  published: {
    loading: false,
    error: null,
    availableQuestionnaires: [],
    publishedQuestionnaires: [],
  },
};

const PublishedState = (props) => {
  // state allows us to access anything in the state and dispatch allows dispatching objects to the reducer
  // populate the publishedReducer with the initial state to instantiate it
  const [state, dispatch] = useReducer(publishedReducer, initialState);

  const setPublishedQuestionnaires = (data) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: LIST_PUBLISHED_QUESTIONNAIRES,
      payload: data,
    });
  };

  const listPublishedQuestionnaires = async () => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: GET_ALL_PUBLISHED_QUESTIONNAIRES_LOADING,
      payload: true,
    });

    await getAll()
      .then((result) => {
        setPublishedQuestionnaires(result);
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_ALL_PUBLISHED_QUESTIONNAIRES_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: SAVE_PUBLISHED_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const savePublishedQuestionnaire = async (data) => {
    dispatch({
      type: SAVE_PUBLISHED_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    await savePublished(data)
      .then((result) => {
        dispatch({
          type: SAVE_PUBLISHED_QUESTIONNAIRE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SAVE_PUBLISHED_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: SAVE_PUBLISHED_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const editPublishedQuestionnaire = async (data) => {
    dispatch({
      type: EDIT_PUBLISHED_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    await editPublished(data)
      .then((result) => {
        dispatch({
          type: EDIT_PUBLISHED_QUESTIONNAIRE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: EDIT_PUBLISHED_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: EDIT_PUBLISHED_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const deletePublishedQuestionnaire = async (data) => {
    dispatch({
      type: DELETE_PUBLISHED_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    await deletePublished(data)
      .then((result) => {
        dispatch({
          type: DELETE_PUBLISHED_QUESTIONNAIRE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: DELETE_PUBLISHED_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: DELETE_PUBLISHED_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const getSinglePublishedQuestionnaire = async (data) => {
    dispatch({
      type: GET_PUBLISHED_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    const response = await getSinglePublished(data)
      .then((result) => {
        dispatch({
          type: GET_PUBLISHED_QUESTIONNAIRE_SUCCESS,
        });
        dispatch({
          type: GET_PUBLISHED_QUESTIONNAIRE_LOADING,
          payload: false,
        });

        if (result?.items?.length > 0) {
          return result?.items[0];
        } else {
          console.error('no items found');
          return null;
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_PUBLISHED_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      });

    return response;
  };

  const listAvailableQuestionnaires = async () => {
    dispatch({
      type: GET_ACTIVE_QUESTIONNAIRES_LOADING,
      payload: true,
    });

    const response = await getAllActive()
      .then((result) => {
        dispatch({
          type: GET_ACTIVE_QUESTIONNAIRES_SUCCESS,
          payload: result,
        });
        dispatch({
          type: GET_ACTIVE_QUESTIONNAIRES_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_ACTIVE_QUESTIONNAIRES_ERROR,
          payload: error,
        });
      });

    return response;
  };

  const setElementValue = (name, value) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: SET_ELEMENT_VALUE,
      payload: {
        name,
        value,
      },
    });
  };

  return (
    <PublishedContext.Provider
      value={{
        state: state || {},
        setElementValue,
        listPublishedQuestionnaires,
        savePublishedQuestionnaire,
        editPublishedQuestionnaire,
        deletePublishedQuestionnaire,
        getSinglePublishedQuestionnaire,
        listAvailableQuestionnaires,
      }}
    >
      {props.children}
    </PublishedContext.Provider>
  );
};

export default PublishedState;
