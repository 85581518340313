// Redux for moving attributes around with state

import {
  SET_ELEMENT_VALUE,
  LIST_ARCHIVED_QUESTIONNAIRES,
  GET_ALL_ARCHIVED_QUESTIONNAIRES_LOADING,
  GET_ALL_ARCHIVED_QUESTIONNAIRES_ERROR,
  SAVE_ARCHIVED_QUESTIONNAIRE_LOADING,
  SAVE_ARCHIVED_QUESTIONNAIRE_SUCCESS,
  SAVE_ARCHIVED_QUESTIONNAIRE_ERROR,
  EDIT_ARCHIVED_QUESTIONNAIRE_LOADING,
  EDIT_ARCHIVED_QUESTIONNAIRE_SUCCESS,
  EDIT_ARCHIVED_QUESTIONNAIRE_ERROR,
  DELETE_ARCHIVED_QUESTIONNAIRE_LOADING,
  DELETE_ARCHIVED_QUESTIONNAIRE_SUCCESS,
  DELETE_ARCHIVED_QUESTIONNAIRE_ERROR,
  GET_ARCHIVED_QUESTIONNAIRE_LOADING,
  GET_ARCHIVED_QUESTIONNAIRE_SUCCESS,
  GET_ARCHIVED_QUESTIONNAIRE_ERROR,
} from './types';

/* 
  This is the Archived Questionnaires' Context reducer. 
  It accepts action type (string) and updates the store's state accordingly
*/

export default (state, action) => {
  const data = action.payload;

  switch (action.type) {
    // map all action types to corresponding action (change of state)

    case SET_ELEMENT_VALUE:
      const { name, value } = data;
      return {
        ...state,
        archived: {
          ...state.archived,
          [name]: value,
        },
      };

    case LIST_ARCHIVED_QUESTIONNAIRES:
      return {
        ...state,
        archived: {
          ...state.archived,
          archivedQuestionnaires: data,
        },
      };

    case GET_ALL_ARCHIVED_QUESTIONNAIRES_LOADING:
      return {
        ...state,
        archived: {
          ...state.archived,
          loading: data,
        },
      };

    case GET_ALL_ARCHIVED_QUESTIONNAIRES_ERROR:
      return {
        ...state,
        archived: {
          ...state.archived,
          error: data,
        },
      };

    case SAVE_ARCHIVED_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        archived: {
          ...state.archived,
          loading: data,
        },
      };

    case SAVE_ARCHIVED_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        archived: {
          ...state.archived,
          archivedQuestionnaires: data,
        },
      };

    case SAVE_ARCHIVED_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        archived: {
          ...state.archived,
          error: data,
        },
      };

    case EDIT_ARCHIVED_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        archived: {
          ...state.archived,
          loading: data,
        },
      };

    case EDIT_ARCHIVED_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        archived: {
          ...state.archived,
          archivedQuestionnaires: data,
        },
      };

    case EDIT_ARCHIVED_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        archived: {
          ...state.archived,
          error: data,
        },
      };

    case DELETE_ARCHIVED_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        archived: {
          ...state.archived,
          loading: data,
        },
      };

    case DELETE_ARCHIVED_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        archived: {
          ...state.archived,
          archivedQuestionnaires: data,
        },
      };

    case DELETE_ARCHIVED_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        archived: {
          ...state.archived,
          error: data,
        },
      };

    case GET_ARCHIVED_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        archived: {
          ...state.archived,
          loading: data,
        },
      };

    case GET_ARCHIVED_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        archived: {
          ...state.archived,
          archivedQuestionnaires: data,
        },
      };

    case GET_ARCHIVED_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        archived: {
          ...state.archived,
          error: data,
        },
      };

    // if no case is adequate to the action type, then the previous state is returned with no modifications done
    default:
      return state;
  }
};
