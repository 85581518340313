// Variables for the Reducer

export const SET_ELEMENT_VALUE = 'ARCHIVED_SET_ELEMENT_VALUE';
export const LIST_ARCHIVED_QUESTIONNAIRES = 'LIST_ARCHIVED_QUESTIONNAIRES';

export const GET_ALL_ARCHIVED_QUESTIONNAIRES_LOADING =
  'GET_ALL_ARCHIVED_QUESTIONNAIRES_LOADING';
export const GET_ALL_ARCHIVED_QUESTIONNAIRES_ERROR =
  'GET_ALL_ARCHIVED_QUESTIONNAIRES_ERROR';

export const SAVE_ARCHIVED_QUESTIONNAIRE_LOADING =
  'SAVE_ARCHIVED_QUESTIONNAIRE_LOADING';
export const SAVE_ARCHIVED_QUESTIONNAIRE_SUCCESS =
  'SAVE_ARCHIVED_QUESTIONNAIRE_SUCCESS';
export const SAVE_ARCHIVED_QUESTIONNAIRE_ERROR =
  'SAVE_ARCHIVED_QUESTIONNAIRE_ERROR';

export const EDIT_ARCHIVED_QUESTIONNAIRE_LOADING =
  'EDIT_ARCHIVED_QUESTIONNAIRE_LOADING';
export const EDIT_ARCHIVED_QUESTIONNAIRE_SUCCESS =
  'EDIT_ARCHIVED_QUESTIONNAIRE_SUCCESS';
export const EDIT_ARCHIVED_QUESTIONNAIRE_ERROR =
  'EDIT_ARCHIVED_QUESTIONNAIRE_ERROR';

export const DELETE_ARCHIVED_QUESTIONNAIRE_LOADING =
  'DELETE_ARCHIVED_QUESTIONNAIRE_LOADING';
export const DELETE_ARCHIVED_QUESTIONNAIRE_SUCCESS =
  'DELETE_ARCHIVED_QUESTIONNAIRE_SUCCESS';
export const DELETE_ARCHIVED_QUESTIONNAIRE_ERROR =
  'DELETE_ARCHIVED_QUESTIONNAIRE_ERROR';

export const GET_ARCHIVED_QUESTIONNAIRE_LOADING =
  'GET_ARCHIVED_QUESTIONNAIRE_LOADING';
export const GET_ARCHIVED_QUESTIONNAIRE_SUCCESS =
  'GET_ARCHIVED_QUESTIONNAIRE_SUCCESS';
export const GET_ARCHIVED_QUESTIONNAIRE_ERROR =
  'GET_ARCHIVED_QUESTIONNAIRE_ERROR';
