import { useReducer } from 'react';
import {
  deletePurchased,
  editPurchased,
  getAll,
  getAllStarted,
  getSinglePurchased,
  savePurchased,
  saveProgress,
} from '../../api/purchased';
import PurchasedContext from './PurchasedContext';
import purchasedReducer from './PurchasedReducer';
import {
  SET_ELEMENT_VALUE,
  LIST_PURCHASED_QUESTIONNAIRES,
  GET_ALL_PURCHASED_QUESTIONNAIRES_LOADING,
  GET_ALL_PURCHASED_QUESTIONNAIRES_ERROR,
  SAVE_PURCHASED_QUESTIONNAIRE_LOADING,
  SAVE_PURCHASED_QUESTIONNAIRE_ERROR,
  SAVE_PURCHASED_QUESTIONNAIRE_SUCCESS,
  EDIT_PURCHASED_QUESTIONNAIRE_ERROR,
  EDIT_PURCHASED_QUESTIONNAIRE_LOADING,
  EDIT_PURCHASED_QUESTIONNAIRE_SUCCESS,
  DELETE_PURCHASED_QUESTIONNAIRE_LOADING,
  DELETE_PURCHASED_QUESTIONNAIRE_SUCCESS,
  DELETE_PURCHASED_QUESTIONNAIRE_ERROR,
  GET_PURCHASED_QUESTIONNAIRE_LOADING,
  GET_PURCHASED_QUESTIONNAIRE_SUCCESS,
  GET_PURCHASED_QUESTIONNAIRE_ERROR,
  SET_QUESTIONNAIRES_WITH_PROGRESS,
  SAVE_QUESTIONNAIRE_PROGRESS_LOADING,
  SAVE_QUESTIONNAIRE_PROGRESS_SUCCESS,
  SAVE_QUESTIONNAIRE_PROGRESS_ERROR,
} from './types';

export const initialState = {
  purchased: {
    loading: false,
    error: null,
    purchasedQuestionnaires: [],
    questionnairesWithProgress: [],
  },
};

const PurchasedState = (props) => {
  // state allows us to access anything in the state and dispatch allows dispatching objects to the reducer
  // populate the purchasedReducer with the initial state to instantiate it
  const [state, dispatch] = useReducer(purchasedReducer, initialState);

  const setPurchasedQuestionnaires = (data) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: LIST_PURCHASED_QUESTIONNAIRES,
      payload: data,
    });
  };

  const setQuestionnairesWithProgress = (data) => {
    dispatch({
      type: SET_QUESTIONNAIRES_WITH_PROGRESS,
      payload: data,
    });
  };

  const listPurchasedQuestionnaires = async () => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: GET_ALL_PURCHASED_QUESTIONNAIRES_LOADING,
      payload: true,
    });

    Promise.all([getAll(), getAllStarted()])
      .then(([allPurchased, allStarted]) => {
        let dataToSave = allPurchased;

        if (allStarted?.length) {
          // if there are already started questionnaires
          allStarted = allStarted.map((started) => {
            if (started.pureData && typeof started.pureData === 'string') {
              started.pureData = JSON.parse(started.pureData); // pureData in DB is saved as string, so we need to parse it
            }
            return started;
          });

          setQuestionnairesWithProgress(allStarted);

          dataToSave = dataToSave?.map((purchased) => {
            let newData = purchased;
            const progressData = allStarted?.find(
              (started) =>
                started?.pureData?.creationTime === newData?.creationTime
            );

            if (progressData) {
              newData = {
                ...newData,
                hasProgress: true,
                ...(progressData?.resultId && {
                  resultId: progressData?.resultId,
                }),
                activeQuestionIndex:
                  progressData?.pureData?.activeQuestionIndex || 23,
              };
            }

            return newData;
          });
        }

        setPurchasedQuestionnaires(dataToSave);
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_ALL_PURCHASED_QUESTIONNAIRES_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: SAVE_PURCHASED_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const saveQuestionnaireProgress = async (data) => {
    dispatch({
      type: SAVE_QUESTIONNAIRE_PROGRESS_LOADING,
      payload: true,
    });

    const res = await saveProgress(data)
      .then((result) => {
        dispatch({
          type: SAVE_QUESTIONNAIRE_PROGRESS_SUCCESS,
        });
        return result?.data?.results;
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SAVE_QUESTIONNAIRE_PROGRESS_ERROR,
          payload: error,
        });
      })
      .then((result) => {
        dispatch({
          type: SAVE_QUESTIONNAIRE_PROGRESS_LOADING,
          payload: false,
        });
        return result;
      });

    return res;
  };

  const savePurchasedQuestionnaire = async (data) => {
    dispatch({
      type: SAVE_PURCHASED_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    await savePurchased(data)
      .then((result) => {
        dispatch({
          type: SAVE_PURCHASED_QUESTIONNAIRE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SAVE_PURCHASED_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: SAVE_PURCHASED_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const editPurchasedQuestionnaire = async (data) => {
    dispatch({
      type: EDIT_PURCHASED_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    await editPurchased(data)
      .then((result) => {
        dispatch({
          type: EDIT_PURCHASED_QUESTIONNAIRE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: EDIT_PURCHASED_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: EDIT_PURCHASED_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const deletePurchasedQuestionnaire = async (data) => {
    dispatch({
      type: DELETE_PURCHASED_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    await deletePurchased(data)
      .then((result) => {
        dispatch({
          type: DELETE_PURCHASED_QUESTIONNAIRE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: DELETE_PURCHASED_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: DELETE_PURCHASED_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const getSinglePurchasedQuestionnaire = async (data) => {
    dispatch({
      type: GET_PURCHASED_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    const response = await getSinglePurchased(data)
      .then((result) => {
        dispatch({
          type: GET_PURCHASED_QUESTIONNAIRE_SUCCESS,
        });
        dispatch({
          type: GET_PURCHASED_QUESTIONNAIRE_LOADING,
          payload: false,
        });

        if (result?.items?.length > 0) {
          return result?.items[0];
        } else {
          console.error('no items found');
          return null;
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_PURCHASED_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      });

    return response;
  };

  const setElementValue = (name, value) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: SET_ELEMENT_VALUE,
      payload: {
        name,
        value,
      },
    });
  };

  return (
    <PurchasedContext.Provider
      value={{
        state: state || {},
        setElementValue,
        listPurchasedQuestionnaires,
        savePurchasedQuestionnaire,
        editPurchasedQuestionnaire,
        deletePurchasedQuestionnaire,
        getSinglePurchasedQuestionnaire,
        setQuestionnairesWithProgress,
        saveQuestionnaireProgress,
      }}
    >
      {props.children}
    </PurchasedContext.Provider>
  );
};

export default PurchasedState;
