import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

export const DropdownComponent = ({
  onChange,
  defaultValue = null,
  options,
  label,
  name,
  style = {},
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Autocomplete
        name={name}
        options={options}
        value={value}
        sx={{ width: 300, mt: 2, ...style }}
        autoHighlight
        autoComplete
        getOptionLabel={(option) => option}
        onChange={(_, newValue) => {
          setValue(newValue);
          onChange(name, newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} name={name} label={label} />
        )}
        {...{ rest }}
      />
    </>
  );
};
