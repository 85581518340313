import { getUserGroup } from 'helpers';
import { SET_USER_INFO, SET_USER_GROUP } from './types';

export default (state, action) => {
  switch (action.type) {
    case SET_USER_INFO: {
      const userData = action.payload;
      const userGroup = getUserGroup(userData);

      return {
        ...state,
        user: userData,
        userGroup: userGroup,
      };
    }

    case SET_USER_GROUP: {
      // TODO: check if we need this
      return {
        ...state,
        userGroup: action.payload,
      };
    }

    default:
      return state;
  }
};
