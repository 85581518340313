import { useReducer } from 'react';
import QuestionnaireContext from './QuestionnaireContext';
import questionnaireReducer from './QuestionnaireReducer';
import {
  SET_SELECTED_QUESTION_SUCCESS,
  SET_QUESTIONNAIRE_SUCCESS,
} from './types';

export const initialState = {
  questionnaire: {
    loading: false,
    error: null,
    questionnaireData: {},
    selectedQuestion: {},
  },
};

const QuestionnaireState = (props) => {
  // state allows us to access anything in the state and dispatch allows dispatching objects to the reducer
  // populate the purchasedReducer with the initial state to instantiate it
  const [state, dispatch] = useReducer(questionnaireReducer, initialState);

  const setQuestionnaire = (data) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: SET_QUESTIONNAIRE_SUCCESS,
      payload: data,
    });
  };

  const setSelectedQuestion = (data) => {
    // dispatch action to the reducer and update the state accordingly
    // TODO in this data object, we should have the progress data as well...(from new EP)
    dispatch({
      type: SET_SELECTED_QUESTION_SUCCESS,
      payload: data,
    });
  };

  return (
    <QuestionnaireContext.Provider
      value={{
        state: state || {},
        setQuestionnaire,
        setSelectedQuestion,
      }}
    >
      {props.children}
    </QuestionnaireContext.Provider>
  );
};

export default QuestionnaireState;
