export const getUserGroup = (userData) => {
  if (!userData) {
    return 'notAuthorised';
  }
  return userData['cognito:groups'] && userData['cognito:groups'].length
    ? userData['cognito:groups'][0]
    : 'noUserGroup';
};

export const getQuestionnaireUniqueId = (creationTime, userId) => {
  if (!creationTime) return null;

  let hash1 = null;
  if (userId) {
    hash1 = userId.toString().slice(-5);
  }

  return `${hash1}${creationTime}`;
};

// /* Randomize array in-place using Durstenfeld shuffle algorithm */
// export function shuffleArray(array) {
//   const newArr = array;
//   for (let i = newArr.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
//   }

//   return newArr;
// }

export const shuffle = (array) => {
  const tmp = array;
  tmp.sort(() => Math.random() - 0.5);
  return tmp;
};

export const randomizeQuestionsAndAnswers = (questionnaire) => {
  if (!questionnaire) return null;

  let result = deepCopy(questionnaire);
  const randomizedQuestions = shuffle(result?.infodata?.questionsList);

  randomizedQuestions.forEach((question, index) => {
    let randomizedAnswers = shuffle(question?.answers);
    randomizedAnswers = randomizedAnswers.map((answer) => {
      return { ...answer, isSelected: false };
    });

    randomizedQuestions[index] = {
      ...randomizedQuestions[index],
      answers: randomizedAnswers,
    };
  });

  return randomizedQuestions;
};

export const formatDateFromMiliseconds = (miliseconds) => {
  const date = new Date(parseInt(miliseconds, 10));
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${day < 10 ? '0' : ''}${day}/${
    month < 10 ? '0' : ''
  }${month}/${date.getFullYear()}`;
};

export const formatTimeFromMiliseconds = (miliseconds) => {
  const date = new Date(parseInt(miliseconds, 10));
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${hours < 10 ? '0' : ''}${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const deepCopy = (element) => JSON.parse(JSON.stringify(element));
