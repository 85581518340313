import axios from 'axios';

export const getAllActive = async () => {
  const token = localStorage.getItem('access_token');

  const { data } = await axios.get(
    `${process.env.REACT_APP_PUBLISHED_API_PATH}/active`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data['dynamodb_result']?.Items;
};

export const getAll = async () => {
  const token = localStorage.getItem('access_token');

  const { data } = await axios.get(
    `${process.env.REACT_APP_PUBLISHED_API_PATH}/all`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data.items;
};

export const savePublished = async (data) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.post(
    `${process.env.REACT_APP_PUBLISHED_API_PATH}/go`,
    { ...data, status: 'published', versionNo: data.versionNo++ || 1 },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};

export const editPublished = async (data) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.put(
    `${process.env.REACT_APP_PUBLISHED_API_PATH}/go`,
    { ...data, status: 'published', versionNo: data.versionNo++ || 1 },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};

export const deletePublished = async (creationTime) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.delete(
    `${process.env.REACT_APP_PUBLISHED_API_PATH}/go`,
    {
      data: {
        creationTime: creationTime,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};

export const getSinglePublished = async (creationTime) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.get(
    `${process.env.REACT_APP_PUBLISHED_API_PATH}/go?creationTime=${creationTime}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};
