// Redux for moving attributes around with state

import {
  SET_ELEMENT_VALUE,
  LIST_PURCHASED_QUESTIONNAIRES,
  GET_ALL_PURCHASED_QUESTIONNAIRES_LOADING,
  GET_ALL_PURCHASED_QUESTIONNAIRES_ERROR,
  SAVE_PURCHASED_QUESTIONNAIRE_LOADING,
  SAVE_PURCHASED_QUESTIONNAIRE_SUCCESS,
  SAVE_PURCHASED_QUESTIONNAIRE_ERROR,
  EDIT_PURCHASED_QUESTIONNAIRE_LOADING,
  EDIT_PURCHASED_QUESTIONNAIRE_SUCCESS,
  EDIT_PURCHASED_QUESTIONNAIRE_ERROR,
  DELETE_PURCHASED_QUESTIONNAIRE_LOADING,
  DELETE_PURCHASED_QUESTIONNAIRE_SUCCESS,
  DELETE_PURCHASED_QUESTIONNAIRE_ERROR,
  GET_PURCHASED_QUESTIONNAIRE_LOADING,
  GET_PURCHASED_QUESTIONNAIRE_SUCCESS,
  GET_PURCHASED_QUESTIONNAIRE_ERROR,
  SET_QUESTIONNAIRES_WITH_PROGRESS,
  SAVE_QUESTIONNAIRE_PROGRESS_LOADING,
  SAVE_QUESTIONNAIRE_PROGRESS_ERROR,
} from './types';

/* 
  This is the Purchased Questionnaires' Context reducer. 
  It accepts action type (string) and updates the store's state accordingly
*/

export default (state, action) => {
  const data = action.payload;

  switch (action.type) {
    // map all action types to corresponding action (change of state)

    case SET_ELEMENT_VALUE:
      const { name, value } = data;
      return {
        ...state,
        purchased: {
          ...state.purchased,
          [name]: value,
        },
      };

    case LIST_PURCHASED_QUESTIONNAIRES:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          purchasedQuestionnaires: data,
        },
      };

    case SET_QUESTIONNAIRES_WITH_PROGRESS:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          questionnairesWithProgress: data,
        },
      };

    case GET_ALL_PURCHASED_QUESTIONNAIRES_LOADING:
    case SAVE_QUESTIONNAIRE_PROGRESS_LOADING:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          loading: data,
        },
      };

    case GET_ALL_PURCHASED_QUESTIONNAIRES_ERROR:
    case SAVE_QUESTIONNAIRE_PROGRESS_ERROR:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          error: data,
        },
      };

    case SAVE_PURCHASED_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          loading: data,
        },
      };

    case SAVE_PURCHASED_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          purchasedQuestionnaires: data,
        },
      };

    case SAVE_PURCHASED_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          error: data,
        },
      };

    case EDIT_PURCHASED_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          loading: data,
        },
      };

    case EDIT_PURCHASED_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          purchasedQuestionnaires: data,
        },
      };

    case EDIT_PURCHASED_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          error: data,
        },
      };

    case DELETE_PURCHASED_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          loading: data,
        },
      };

    case DELETE_PURCHASED_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          purchasedQuestionnaires: data,
        },
      };

    case DELETE_PURCHASED_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          error: data,
        },
      };

    case GET_PURCHASED_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          loading: data,
        },
      };

    case GET_PURCHASED_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          purchasedQuestionnaires: data,
        },
      };

    case GET_PURCHASED_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        purchased: {
          ...state.purchased,
          error: data,
        },
      };

    // if no case is adequate to the action type, then the previous state is returned with no modifications done
    default:
      return state;
  }
};
