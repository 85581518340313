import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import UserContext from 'context/user/UserContext';
import { getUserGroup } from 'helpers.js';

export const ProtectedRoute = ({ allowedRole = '' }) => {
  const { isAuthenticatedUser, user } = useContext(UserContext);
  const isAuth = isAuthenticatedUser();

  if (!isAuth) {
    return <Navigate to="/login" />;
  }
  if (!allowedRole) {
    return <Outlet />;
  }

  const userGroup = getUserGroup(user);

  if (allowedRole === userGroup) {
    return <Outlet />;
  } else {
    return <Navigate to="/404" />;
  }
};
