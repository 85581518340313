import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Paper, Grid, Button } from '@mui/material';
import { AnswerOption, CustomizedDialog } from 'components/common';
import QuestionnaireContext from 'context/questionnaire/QuestionnaireContext';
import PurchasedContext from 'context/purchased/PurchasedContext';
import { deepCopy, randomizeQuestionsAndAnswers } from 'helpers';
import { getPreviousAttemptsForCurrentYear, saveProgress } from 'api/purchased';

const fallbackData = {};
const answersLetters = ['A', 'B', 'C', 'D', 'E'];

export const Question = ({}) => {
  //TODO add question number logic
  const navigate = useNavigate();
  const { questionnaireId } = useParams();
  const {
    state: questionnaireState,
    setQuestionnaire,
    setSelectedQuestion,
  } = useContext(QuestionnaireContext);

  const [showFullScreenImage, setShowFullScreenImage] = useState(false);
  const [hasImage] = useState(true); // TODO change this logic with check if the question has image link or not..
  const [imageLocation, setImageLocation] = useState(
    '/question-image-placeholder.png'
  );

  const [chosenAnswerIndexes, setChosenAnswerIndexes] = useState([]);
  const [numberOfExpectedAnswers, setNumberOfExpectedAnswers] = useState(1);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);

  const [questionData, setQuestionData] = useState(fallbackData);

  const [questionIndex, setQuestionIndex] = useState(22); // TODO set to 0

  const [timer, setTimer] = useState(null);
  const [timerTimeout, setTimerTimeout] = useState(null);

  //! check
  useEffect(() => {
    // get data for selected questionnaire from local storage
    const localStorageData = JSON.parse(
      localStorage.getItem('selectedQuestionnaire')
    );

    // if there is data in local storage & that data is for the questionnaire that we chose
    if (localStorageData?.pureData?.questionnaireId === questionnaireId) {
      //! check
      // TODO add check if updated time of questionnaire in local storage is newer than the one in DB
      // sets the questionnaire data in context to be the data that we got from local storage
      // sets the questions data in context as well
      setQuestionnaire(localStorageData);

      initializeTimer(
        parseInt(localStorageData?.pureData?.infodata?.themeTimeLimit) * 60 ||
          null
      );

      // index of question which the user got to last time
      const indexOfActiveQuestion =
        localStorageData?.pureData?.activeQuestionIndex;
      setQuestionIndex(indexOfActiveQuestion);

      // data for the selected question - equal to the question (from the questionsList from localStorage) with index activeQuestionIndex
      const selectedQuestion =
        localStorageData?.pureData?.infodata?.questionsList[
          indexOfActiveQuestion
        ];
      // sets the selected question data in context
      setSelectedQuestion(selectedQuestion);

      const indexesOfSelectedAnswers = getIndexesOfSelectedAnswers(
        selectedQuestion?.answers
      );
      setChosenAnswerIndexes(indexesOfSelectedAnswers);

      // sets the number of questions for the chosen questionnaire
      setNumberOfQuestions(
        localStorageData?.pureData?.infodata?.questionsList.length || 0
      );
    } else console.log('enters else but should not');
  }, [questionnaireId]);

  // This useEffect is executed whenever the selected question data is changed in the context
  useEffect(() => {
    // get the selected question data from context
    const selectedQuestion =
      questionnaireState?.questionnaire?.selectedQuestion;

    // update the data for question that is rendered
    if (selectedQuestion) {
      setQuestionData(selectedQuestion);

      // update the number of correct answers
      const numberOfCorrectAnswers = selectedQuestion?.answers?.filter(
        (answer) => answer?.isCorrect
      ).length;
      setNumberOfExpectedAnswers(numberOfCorrectAnswers);
    }
  }, [questionnaireState?.questionnaire?.selectedQuestion]);

  // Timer logic
  useEffect(() => {
    if (timer > 0) {
      const myTimeout = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
      setTimerTimeout(myTimeout);
    } else if (timer === 0) {
      finishQuestionnaire(true, 0);
    }
  }, [timer]);

  // Reactive variable that contains the label for the time left
  // It is updated on every change of the timer value
  // and it is only shown on questionnaires of type "Timed Exam"
  const timeLeft = useMemo(() => {
    if (
      questionnaireState?.questionnaire?.questionnaireData?.themeType ===
      'Timed Exam'
    ) {
      const minutes = Math.floor(timer / 60);
      const seconds = timer - minutes * 60;
      return `${minutes}min ${seconds}s`;
    } else {
      return '';
    }
  }, [timer]);

  // Label displaying the progress of the user
  // the number of expected answers, index of active question and number of total questions
  const progressLabel = useMemo(() => {
    return `${numberOfExpectedAnswers} response${
      numberOfExpectedAnswers > 1 ? 's' : ''
    } expected - Q${questionIndex + 1} out of ${numberOfQuestions} questions`;
  }, [numberOfExpectedAnswers, questionIndex, numberOfQuestions]);

  const toggle = () => setShowFullScreenImage((open) => !open);

  const initializeTimer = (time) => {
    setTimer(time);
  };
  const getUpdatedSelectedAnswers = (answers) => {
    if (!answers || !answers.length) return [];

    let updatedAnswers = answers;
    updatedAnswers.forEach((answer, index) => {
      answer.isSelected = chosenAnswerIndexes.includes(index);
    });
    return updatedAnswers;
  };

  const getIndexesOfSelectedAnswers = (answers) => {
    const indexesOfSelectedAnswers = [];
    answers?.forEach((answer, index) => {
      if (answer?.isSelected) indexesOfSelectedAnswers.push(index);
    });
    return indexesOfSelectedAnswers;
  };

  const handleClickNext = () => {
    if (numberOfQuestions !== questionIndex + 1) {
      // user clicks on "Next"
      // => show next question
      // => save progress in local storage
      updateSelectedQuestion(questionIndex + 1);
    } else {
      // user clicks on "Finish"
      // user has answered the last question
      // => quiz should be ended
      if (timerTimeout) clearTimeout(timerTimeout);
      finishQuestionnaire(true, timer);
    }
  };

  const handleClickPrev = () => {
    updateSelectedQuestion(questionIndex - 1);
  };

  const updateSelectedAnswer = (index) => {
    if (numberOfExpectedAnswers === 1) {
      if (chosenAnswerIndexes.includes(index)) {
        setChosenAnswerIndexes([]);
      } else {
        setChosenAnswerIndexes([index]);
      }
      return;
    }

    if (chosenAnswerIndexes.includes(index)) {
      const newData = chosenAnswerIndexes.filter(
        (answerIndex) => answerIndex !== index
      );
      setChosenAnswerIndexes(newData);
    } else {
      setChosenAnswerIndexes([...chosenAnswerIndexes, index]);
    }
  };

  // sets the selectedQuestion data in Context
  // sets the question index used in this component
  const updateSelectedQuestion = (newQuestionIndex) => {
    if (!questionnaireState?.questionnaire?.questionnaireData) return;

    const questionnaireData = deepCopy(
      questionnaireState.questionnaire.questionnaireData
    );

    questionnaireData.pureData.infodata.questionsList[questionIndex].answers =
      getUpdatedSelectedAnswers(
        questionnaireData.pureData.infodata.questionsList[questionIndex].answers
      );

    if (newQuestionIndex || newQuestionIndex === 0) {
      questionnaireData.pureData.activeQuestionIndex = newQuestionIndex;
      // update the index of selected (displayed) question
      setQuestionIndex(newQuestionIndex);

      let newQuestionData =
        questionnaireData.pureData.infodata.questionsList[newQuestionIndex];
      // update data for the selected (displayed) question
      setSelectedQuestion(newQuestionData);

      const indexesOfSelectedAnswers = getIndexesOfSelectedAnswers(
        newQuestionData.answers
      );
      // preselect answers already answered by the user
      setChosenAnswerIndexes(indexesOfSelectedAnswers);
    }

    questionnaireData.pureData.activeQuestionIndex = questionIndex;

    setQuestionnaire(questionnaireData);
    localStorage.setItem(
      'selectedQuestionnaire',
      JSON.stringify(questionnaireData)
    );
    // TODO use saveProgress method from purchased.js and add logic for it in context

    return questionnaireData;
  };

  /**
   * TODO call this function when user clicks on Start Again button on /questionnaires,
   * or answers the last question and clicks on finish btn
   * set the "completedFlag" to true and count points...
   */
  const finishQuestionnaire = (completeQuiz, timeLeft = 0) => {
    let updatedData = updateSelectedQuestion();

    let dataToSave = {
      pureData: updatedData.pureData,
      questionnaireId: updatedData.pureData.questionnaireId,
      completedFlag: false,
      themeType: updatedData.themeType,
      result: 0,
      resultId: updatedData.resultId,
    };

    if (updatedData.themeType === 'Survey') {
      if (completeQuiz) {
        dataToSave.completedFlag = true;
      }

      saveProgress(dataToSave);
      localStorage.removeItem('selectedQuestionnaire');
      navigate('/questionnaires?selectedTab=Purchased');

      return;
    }

    const timeLimit =
      parseInt(updatedData?.pureData?.infodata?.themeTimeLimit) * 60;
    const timeSpendSec = timeLimit - timeLeft;

    let result = 0;
    let correctQuestions = 0;
    updatedData?.pureData?.infodata?.questionsList?.forEach((question) => {
      const isQuestionSolved = question?.answers.every((answer) => {
        return (
          (answer.isCorrect && answer.isSelected) ||
          (!answer.isCorrect && !answer.isSelected)
        );
      });

      if (isQuestionSolved) {
        result += parseInt(question?.questionPoints) || 0;
        correctQuestions++;
      }
    });

    const wrongQuestions = updatedData?.pureData?.infodata?.questionsList
      ?.length
      ? updatedData?.pureData?.infodata?.questionsList?.length -
        correctQuestions
      : 0;

    dataToSave = {
      ...dataToSave,
      result: result,
    };

    if (completeQuiz) {
      // end quiz
      // => count result
      // => if Timed Exam > store time spent or time left (in seconds)
      // => set completedFlag to true
      dataToSave = {
        ...dataToSave,
        timeSpendSec,
        completedFlag: true,
      };
      // store progress in DB
      saveProgress(dataToSave).then(() => {
        localStorage.removeItem('selectedQuestionnaire');

        // get data for all previous attempts
        // then navigate to results page
        // => display pie chart with number of correct and wrong answered questions in this attempt
        // => display line chart with data for all previous attempts (date and points)
        getPreviousAttemptsForCurrentYear(
          updatedData.pureData.questionnaireId
        ).then((result) => {
          navigate('/results', {
            state: {
              correctQuestions,
              wrongQuestions,
              pastAttempts: result,
            },
          });
        });
      });

      return;
    }

    // store progress in DB
    saveProgress(dataToSave);
    localStorage.removeItem('selectedQuestionnaire');
    navigate('/questionnaires?selectedTab=Purchased');
  };

  return (
    <Box sx={{ width: '75vw', margin: 'auto' }}>
      <Box
        sx={{
          width: '100%',
          minHeight: '95vh',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 2,
            left: 0,
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 2,
              fontSize: '10px',
            }}
          >
            <Typography variant="body" sx={{ color: 'red', fontWeight: '500' }}>
              {timeLeft}
            </Typography>
            <Typography variant="body" sx={{ fontSize: '12px' }}>
              {progressLabel}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '90vh',
            justifyContent: 'center',
            textAlign: 'justify',
            mt: 7,
          }}
        >
          <Paper elevation={0}>{questionData?.questionText}</Paper>
          <Grid container sx={{ mt: hasImage ? 7 : 10, mb: 4 }}>
            {hasImage && (
              <Grid item xs={12} md={hasImage ? 5 : 12} sx={{ m: 'auto' }}>
                <Box
                  component="img"
                  src={imageLocation}
                  sx={{
                    display: 'block',
                    maxWidth: '100%',
                    maxHeight: { xs: '170px', sm: '170px', md: 'none' },
                    marginLeft: { xs: 'auto', sm: 'auto' },
                    marginRight: { xs: 'auto', sm: 'auto' },
                    marginBottom: { xs: '40px', sm: '40px', md: 'auto' },
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={toggle}
                />
              </Grid>
            )}
            <Grid item xs={12} md={hasImage ? 7 : 12} sx={{ m: 'auto' }}>
              <Box
                sx={{
                  maxWidth: '80%',
                  m: 'auto',
                  '& .MuiPaper-root:last-child': { mb: '0' },
                }}
              >
                {questionData?.answers?.length > 0 &&
                  questionData.answers.map((answer, index) => {
                    return (
                      <AnswerOption
                        key={index}
                        letter={answersLetters[index]}
                        text={answer?.label}
                        isChosen={chosenAnswerIndexes.includes(index)}
                        onClick={() => updateSelectedAnswer(index)}
                      />
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Action buttons section */}
        <Box
          sx={{
            height: '40px',
            position: 'absolute',
            bottom: 2,
            left: 0,
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px',
              paddingTop: '0px',
              fontSize: '10px',
            }}
          >
            <Box>
              {/* Previous button */}
              <Button
                variant="text"
                disabled={questionIndex === 0}
                onClick={handleClickPrev}
                sx={{ fontSize: '10px', color: 'black' }}
              >
                Previous
              </Button>

              {/* Exit button */}
              <Button
                variant="outlined"
                onClick={() => {
                  finishQuestionnaire();
                }}
                sx={{
                  ml: 6,
                  fontSize: '10px',
                  color: 'black',
                  border: '1px solid black',
                  '&:hover': {
                    border: '1px solid black',
                  },
                }}
              >
                Exit
              </Button>
            </Box>

            {/* Next button */}
            <Button
              variant="text"
              onClick={handleClickNext}
              disabled={!chosenAnswerIndexes.length}
              sx={{
                display: 'inline',
                float: 'right',
                fontSize: '10px',
                color: 'black',
              }}
            >
              {numberOfQuestions !== questionIndex + 1 ? 'Next' : 'Finish'}
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Dialog showing the question image in fullscreen */}
      {hasImage ? (
        <CustomizedDialog
          isOpen={showFullScreenImage}
          showFullScreenIcon={true}
          toggle={toggle}
        >
          <Box
            component="img"
            src="/question-image-placeholder.png"
            sx={{
              display: 'block',
              maxWidth: '100%',
              height: 'auto',
              maxHeight: '90vh',
              marginLeft: { xs: 'auto', sm: 'auto' },
              marginRight: { xs: 'auto', sm: 'auto' },
            }}
            onClick={toggle}
          />
        </CustomizedDialog>
      ) : null}
    </Box>
  );
};
