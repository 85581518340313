import React from 'react';
import { Box, Alert, IconButton, Collapse } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CustomAlert = ({
  message,
  isOpen,
  onClose,
  severity = 'error',
  variant = 'filled',
}) => {
  return (
    <Box
      sx={{
        width: '90%',
        marginTop: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Collapse in={isOpen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={onClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
          variant={variant}
          severity={severity}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
};
