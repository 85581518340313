import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import ThemeContext from '../../context/theme/ThemeContext';
import { Alert } from 'components/common';
import MainNavBar from './MainNavBar';

/* This is a component that needs to be used as a wrapper of pages that we want  
  to have NavBar on the page and container layout */

const CoreLayout = ({ children }) => {
  const { theme, closeAlert, withTheme } = useContext(ThemeContext);
  const { shouldShowAlert, alertMessage } = theme || {};

  return (
    <Grid item>
      <MainNavBar />
      {shouldShowAlert && (
        <Alert
          message={alertMessage}
          isOpen={shouldShowAlert}
          onClose={closeAlert}
        />
      )}
      <Grid item sx={{ width: '100%' }} className={withTheme('page-wrapper')}>
        <Grid container rowSpacing={1} direction="column" className="container">
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CoreLayout;
