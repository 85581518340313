// Redux for moving attributes around with state

import {
  SET_ELEMENT_VALUE,
  LIST_PUBLISHED_QUESTIONNAIRES,
  GET_ALL_PUBLISHED_QUESTIONNAIRES_LOADING,
  GET_ALL_PUBLISHED_QUESTIONNAIRES_ERROR,
  SAVE_PUBLISHED_QUESTIONNAIRE_LOADING,
  SAVE_PUBLISHED_QUESTIONNAIRE_SUCCESS,
  SAVE_PUBLISHED_QUESTIONNAIRE_ERROR,
  EDIT_PUBLISHED_QUESTIONNAIRE_LOADING,
  EDIT_PUBLISHED_QUESTIONNAIRE_SUCCESS,
  EDIT_PUBLISHED_QUESTIONNAIRE_ERROR,
  DELETE_PUBLISHED_QUESTIONNAIRE_LOADING,
  DELETE_PUBLISHED_QUESTIONNAIRE_SUCCESS,
  DELETE_PUBLISHED_QUESTIONNAIRE_ERROR,
  GET_PUBLISHED_QUESTIONNAIRE_LOADING,
  GET_PUBLISHED_QUESTIONNAIRE_SUCCESS,
  GET_PUBLISHED_QUESTIONNAIRE_ERROR,
  GET_ACTIVE_QUESTIONNAIRES_LOADING,
  GET_ACTIVE_QUESTIONNAIRES_SUCCESS,
  GET_ACTIVE_QUESTIONNAIRES_ERROR,
} from './types';

/* 
  This is the Published Questionnaires' Context reducer. 
  It accepts action type (string) and updates the store's state accordingly
*/

export default (state, action) => {
  const data = action.payload;

  switch (action.type) {
    // map all action types to corresponding action (change of state)

    case SET_ELEMENT_VALUE:
      const { name, value } = data;
      return {
        ...state,
        published: {
          ...state.published,
          [name]: value,
        },
      };

    case LIST_PUBLISHED_QUESTIONNAIRES:
      return {
        ...state,
        published: {
          ...state.published,
          publishedQuestionnaires: data,
        },
      };

    case GET_ACTIVE_QUESTIONNAIRES_LOADING:
    case GET_ALL_PUBLISHED_QUESTIONNAIRES_LOADING:
      return {
        ...state,
        published: {
          ...state.published,
          loading: data,
        },
      };

    case SAVE_PUBLISHED_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        published: {
          ...state.published,
          loading: data,
        },
      };

    case SAVE_PUBLISHED_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        published: {
          ...state.published,
          publishedQuestionnaires: data,
        },
      };

    case EDIT_PUBLISHED_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        published: {
          ...state.published,
          loading: data,
        },
      };

    case EDIT_PUBLISHED_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        published: {
          ...state.published,
          publishedQuestionnaires: data,
        },
      };

    case DELETE_PUBLISHED_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        published: {
          ...state.published,
          loading: data,
        },
      };

    case DELETE_PUBLISHED_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        published: {
          ...state.published,
          publishedQuestionnaires: data,
        },
      };

    case GET_PUBLISHED_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        published: {
          ...state.published,
          loading: data,
        },
      };

    case GET_PUBLISHED_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        published: {
          ...state.published,
          publishedQuestionnaires: data,
        },
      };

    case GET_ALL_PUBLISHED_QUESTIONNAIRES_ERROR:
    case EDIT_PUBLISHED_QUESTIONNAIRE_ERROR:
    case SAVE_PUBLISHED_QUESTIONNAIRE_ERROR:
    case DELETE_PUBLISHED_QUESTIONNAIRE_ERROR:
    case GET_PUBLISHED_QUESTIONNAIRE_ERROR:
    case GET_ACTIVE_QUESTIONNAIRES_ERROR:
      return {
        ...state,
        published: {
          ...state.published,
          error: data,
        },
      };

    case GET_ACTIVE_QUESTIONNAIRES_SUCCESS:
      return {
        ...state,
        published: {
          ...state.published,
          availableQuestionnaires: data,
        },
      };

    // if no case is adequate to the action type, then the previous state is returned with no modifications done
    default:
      return state;
  }
};
