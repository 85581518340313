import axios from 'axios';

export const getAll = async () => {
  const token = localStorage.getItem('access_token');

  const { data } = await axios.get(
    `${process.env.REACT_APP_ARCHIVE_API_PATH}/all`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data.items;
};

export const saveArchive = async (data) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.post(
    `${process.env.REACT_APP_ARCHIVE_API_PATH}/go`,
    { ...data, status: 'archived' },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};

export const editArchive = async (data) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.put(
    `${process.env.REACT_APP_ARCHIVE_API_PATH}/go`,
    { ...data, status: 'archived' },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};

export const deleteArchive = async (creationTime) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.delete(
    `${process.env.REACT_APP_ARCHIVE_API_PATH}/go`,
    {
      data: {
        creationTime: creationTime,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};

export const getSingleArchive = async (creationTime) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.get(
    `${process.env.REACT_APP_ARCHIVE_API_PATH}/go?creationtime=${creationTime}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};
