import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Alert, CustomizedDialog, InputField } from 'components/common';
import Dropzone from 'components/common/Dropzone';
import DraftsContext from 'context/draft/DraftsContext';
import { deepCopy } from 'helpers';
import { getImageByName } from 'api/draft';

const initialData = {
  id: null,
  questionText: '',
  isMultipleChoice: true,
  numberOfCorrectAnswers: 1,
  correctAnswerDescription: '',
  questionPoints: 0,
  questionTimeLimit: 0,
  answers: [
    {
      isCorrect: false,
      label: '',
    },
    {
      isCorrect: false,
      label: '',
    },
    {
      isCorrect: false,
      label: '',
    },
    {
      isCorrect: false,
      label: '',
    },
    {
      isCorrect: false,
      label: '',
    },
  ],
};

const answerInputFieldStyle = {
  marginRight: '20px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
    backgroundColor: 'rgba(240, 240, 197, 0.6)',
  },
};

export const AddQuestion = () => {
  const [questionnaireData, setQuestionnaireData] = useState({});
  const [questionsData, setQuestionsData] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isCheckboxRequired, setIsCheckboxRequired] = useState(true);
  const [alertData, setAlertData] = useState({ isOpen: false, message: '' });

  const { themeId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    state: draftState,
    getSingleDraftsQuestionnaire,
    editDraftsQuestionnaire,
    setSelectedDraftQuestionnaire,
  } = useContext(DraftsContext);

  useEffect(() => {
    // if data is passed when navigating to the page, set that data for the questionnaire
    if (state?.data) {
      setSelectedDraftQuestionnaire(state.data);
    } else {
      // otherwise, get the questionnaire data from DB and put it in state
      const creationTime = themeId.slice(5);
      getSingleDraftsQuestionnaire(creationTime).then(
        (selectedQuestionnaire) => {
          setSelectedDraftQuestionnaire(selectedQuestionnaire);
        }
      );
    }
  }, [themeId, state]);

  useEffect(() => {
    // this use effect is called when we are changing the url or selecting different questionnaire
    // firstly we need to check in the local storage if there is new data for the questions inside that questionnaire
    // if not, then we display the data that is in the state
    setQuestionnaireData(draftState?.draft?.selectedDraftQuestionnaire);

    // this is the list of questions that are inside the selected/opened questionnaire
    const questionsList =
      draftState?.draft?.selectedDraftQuestionnaire?.infodata?.questionsList;

    // if the selected questionnaire already has questions
    // then set the selected questionnaire data and preselect the first/last? question and fill in the question form
    if (questionsList) {
      setQuestionsData(questionsList);
      // next two lines are commented out because of a problem when editing last (first) question the UX on save is awkward
      // setSelectedQuestion(questionsList[0]);
      // setSelectedQuestionIndex(0);
    }
  }, [draftState?.draft?.selectedDraftQuestionnaire]);

  // TODO: save state in local storage
  const handleAnswerChanged = (answerIndex, newAnswerData) => {
    if (!isFormDirty) {
      setIsFormDirty(true);
    }

    let currentQuestion = selectedQuestion;

    if (!currentQuestion || currentQuestion.answers?.length !== 5) {
      // we are in create question mode
      currentQuestion = {
        ...currentQuestion,
        answers: deepCopy(initialData.answers),
      };
    }

    // get answers for the selected question
    const answers = currentQuestion.answers;

    if (!answers || answers.length - 1 < answerIndex) {
      // TODO implement
    } else {
    }

    // change the answer's data
    answers[answerIndex] = newAnswerData;

    // This updates the data of the selected question
    setSelectedQuestion({ ...currentQuestion, answers });
    // setIsCheckboxRequired(validateAnswerCheckboxes());

    // TODO: call updateQuestionsData and send all updated questions and answers
    // updateQuestionsData(newArray);
  };

  // This function handles the change of all input fields
  // Arguments that it expects are: name of the property that is changed and the corresponding value
  const handleChange = (name, value) => {
    // If some data is changed in the form then the form should be set as dirty so the Save button could be enabled
    if (!isFormDirty) {
      setIsFormDirty(true);
    }

    // This updates the data of the selected question
    setSelectedQuestion({ ...selectedQuestion, ...{ [name]: value } });
    // TODO: call updateQuestionsData and send all updated questions and answers
    // updateQuestionsData(newArray);
  };

  // This function is called when a question is selected from the array of questions
  const handleQuestionSelected = (questionIndex) => {
    // initialy the form is set as clean (not dirty) - which will disable the save button
    setIsFormDirty(false);
    // set the selected question index in state
    setSelectedQuestionIndex(questionIndex);
    // set the selected question data in state
    setSelectedQuestion(questionsData[questionIndex]);
  };

  const showPreviousQuestion = () => {
    // Method that shows the previous question
    // If the first question is selected then there is no previous question to be shown
    if (selectedQuestionIndex !== 0)
      handleQuestionSelected(selectedQuestionIndex - 1);
  };

  const showNextQuestion = () => {
    // Method that shows the next question
    // If the last question is selected then there is no next question to be shown
    if (selectedQuestionIndex < questionsData?.length - 1)
      handleQuestionSelected(selectedQuestionIndex + 1);
  };

  const updateQuestionsData = (newData) => {
    setQuestionsData(newData);
    localStorage.setItem('questionsData', JSON.stringify(newData));
  };

  const handleDelete = () => {
    // remove the question by id from the array
    // the questions array is filtered and all of the questions with different ID than the selected question's are returned
    const tmp = questionsData?.filter(
      (question) => question.id !== selectedQuestion?.id
    );
    // after deleting, if the selected question was not first - then show the previous question
    // otherwise show next question
    if (selectedQuestionIndex > 0) {
      showPreviousQuestion();
    } else {
      showNextQuestion();
    }

    const res = {
      ...draftState?.draft?.selectedDraftQuestionnaire,
      infodata: {
        ...draftState?.draft?.selectedDraftQuestionnaire?.infodata,
        questionsList: tmp,
      },
    };

    const resToSave = {
      ...draftState?.draft?.selectedDraftQuestionnaire?.infodata,
      questionsList: tmp,
    };

    editDraftsQuestionnaire(resToSave);
    setSelectedDraftQuestionnaire(res);

    // update the questions array in state
    updateQuestionsData(tmp);
    // set the form as clean so the Save button could be disabled
    setIsFormDirty(false);
    // close the delete dialog
    setShowDeleteDialog(false);
  };

  const onDrop = async (file) => {
    // TODO implement

    // const token = localStorage.getItem('access_token');
    // console.log('file', file);

    // const formData = new FormData();
    // formData.append('image', file);

    // var options = {
    //   headers: {
    //     'Content-Type': file.type,
    //     Authorization: `Bearer ${token}`,
    //   },
    // };

    // const cognitoId = questionnaireData?.userId;
    // const themeName = questionnaireData?.infodata?.themeName;
    // const questionNo = selectedQuestion?.id;

    const image = await getImageByName('name');
    console.log('image', image);

    // await axios
    //   .put(
    //     `https://egesjx3321.execute-api.eu-west-2.amazonaws.com/prod1/media01-vouch/${cognitoId}_${themeName}_${questionNo}.jpg`,
    //     formData,
    //     options
    //   )
    //   .catch((error) => console.log(error.response))
    //   .then(function (result) {
    //     console.log('res', result);
    //   });
  };

  const onUploadError = (errorMessage) => {
    // TODO call this method on API error as well
    setAlertData({ isOpen: true, message: errorMessage });
    setTimeout(() => {
      setAlertData({ isOpen: false, message: '' });
    }, 5000); //close alert after 5 seconds
  };

  const handleSubmit = (e) => {
    // prevent the page from refreshing after submit
    e.preventDefault();

    if (questionsData) {
      let newArray = deepCopy(questionsData);
      // if there is no question selected then we are in create mode
      // and new question should be added at the beginning of the questions array

      if (!selectedQuestion?.id && selectedQuestion.id !== 0) {
        const ids = newArray.map((object) => object.id);
        const max = ids.length > 0 ? Math.max(...ids) : 1;

        newArray.unshift({
          ...selectedQuestion,
          id: max + 1,
        });
      } else {
        // if there is a selected question then we are in edit mode
        // and the corresponding question shoulb be updated
        let index = questionsData.findIndex(
          (el) => el.id === selectedQuestion?.id
        );
        // TODO: check if this index calculation can be changed by using only "selectedQuestionIndex"
        newArray[index] = selectedQuestion;
      }

      const res = {
        ...draftState?.draft?.selectedDraftQuestionnaire,
        infodata: {
          ...draftState?.draft?.selectedDraftQuestionnaire?.infodata,
          questionsList: newArray,
        },
      };

      const resToSave = {
        ...draftState?.draft?.selectedDraftQuestionnaire?.infodata,
        questionsList: newArray,
      };

      editDraftsQuestionnaire(resToSave);
      setSelectedDraftQuestionnaire(res);

      // update the questions array in state
      updateQuestionsData(newArray);
      // show create form (reset the entered data in the form)
      const tmp = deepCopy(initialData);
      setSelectedQuestion(tmp);
      // set the form as clean so the Save button could be disabled
      setIsFormDirty(false);
    }
  };

  const validateAnswerCheckboxes = () => {
    return (
      selectedQuestion?.answers?.filter((answer) => answer.isCorrect).length ===
      0
    );
  };

  return (
    <>
      <Alert
        message={alertData?.message}
        isOpen={alertData?.isOpen}
        onClose={() => {
          setAlertData({ isOpen: false, message: '' });
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          position: 'relative',
          alignItems: 'center',
          mb: '30px',
          mt: '30px',
          ml: '40px',
          mr: '40px',
        }}
      >
        <Box>
          <Button
            variant="outlined"
            onClick={() => {
              navigate(`/theme/${themeId}`, {
                state: {
                  data: {
                    ...draftState?.draft?.selectedDraftQuestionnaire,
                    infodata: draftState?.draft?.selectedDraftQuestionnaire
                      ?.infodata
                      ? draftState?.draft?.selectedDraftQuestionnaire?.infodata
                      : draftState?.draft?.selectedDraftQuestionnaire,
                  },
                  themeState: 'draft',
                },
              });
            }}
          >
            Back
          </Button>
        </Box>

        <Box>
          <Typography sx={{ mb: '5px' }} variant="h5">
            Editing{' '}
            {draftState?.draft?.selectedDraftQuestionnaire?.infodata?.themeName}
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="info"
          onClick={() => setSelectedQuestion(deepCopy(initialData))}
          endIcon={<AddCircleOutlineIcon />}
          sx={{ float: 'right', height: 'max-content' }}
        >
          Add new question
        </Button>
      </Box>
      <Grid container sx={{ minHeight: '80vh', padding: '20px' }}>
        <Grid
          item
          xs={0}
          sm={4}
          lg={3}
          xl={2}
          sx={{
            borderRight: 'solid 2px #00000054',
            padding: '20px',
            maxHeight: '80vh',
            overflow: 'scroll',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {questionsData?.map((question, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    height: '80px',
                    background: 'whitesmoke',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '15px',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                    border:
                      selectedQuestion?.id === question.id
                        ? '2px solid blue'
                        : 'none',
                  }}
                  onClick={() => handleQuestionSelected(index)}
                >
                  <Box
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    #{questionsData.length - index} {question?.questionText}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          sm={8}
          lg={9}
          xl={10}
          sx={{ padding: '20px', width: '100%' }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex' }}>
              <InputField
                multiline={true}
                rows={5}
                name="questionText"
                label="Question Text"
                value={selectedQuestion?.questionText || ''}
                required={true}
                fullWidth={true}
                onChange={handleChange}
                style={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 0,
                    boxShadow: '0px 0px 10px 1px #00000010',
                  },
                }}
              />

              <Dropzone handleDrop={onDrop} onError={onUploadError} />
            </Box>

            {Array(5)
              .fill()
              .map((_, index) => {
                const answerData = selectedQuestion?.answers
                  ? selectedQuestion.answers[index]
                  : { label: '', isChecked: false };

                return (
                  <Box
                    sx={{ display: 'flex', flexDirection: 'row' }}
                    key={index}
                  >
                    <InputField
                      name={`answer-text-${index}`}
                      label={`Answer ${index + 1}`}
                      value={answerData.label || ''}
                      required={true}
                      fullWidth={true}
                      onChange={(_, value) => {
                        handleAnswerChanged(index, {
                          label: value,
                          isCorrect: answerData.isCorrect || false,
                        });
                      }}
                      style={answerInputFieldStyle}
                    />
                    <FormControlLabel
                      sx={{
                        marginBottom: '16px',
                        '& .MuiFormControlLabel-label': {
                          width: 'max-content',
                        },
                      }}
                      control={
                        <Checkbox
                          name="isCorrect"
                          color="success"
                          checked={answerData.isCorrect || false}
                          required={isCheckboxRequired}
                          onChange={(event) =>
                            handleAnswerChanged(index, {
                              label: answerData.label,
                              isCorrect: event?.target?.checked,
                            })
                          }
                        />
                      }
                      label="Correct"
                    />
                  </Box>
                );
              })}

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                {/* if the theme is not survey than it should have the option of points per question */}
                {draftState?.draft?.selectedDraftQuestionnaire?.themeType &&
                  draftState?.draft?.selectedDraftQuestionnaire?.themeType !==
                    'Survey' && (
                    <InputField
                      name="questionPoints"
                      label="Points for question"
                      type="number"
                      value={selectedQuestion?.questionPoints || 0}
                      required={true}
                      fullWidth={true}
                      onChange={handleChange}
                      style={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 0,
                          boxShadow: '0px 0px 10px 1px #00000010',
                        },
                      }}
                    />
                  )}
              </Grid>
              <Grid item xs={6}>
                {/* if it is timed exam than it should have the option of time per question */}
                {draftState?.draft?.selectedDraftQuestionnaire?.themeType ===
                  'Timed Exam' && (
                  <InputField
                    name="questionTimeLimit"
                    label="Time for answering the question (in minutes)"
                    type="number"
                    value={selectedQuestion?.questionTimeLimit || 0}
                    required={true}
                    fullWidth={true}
                    onChange={handleChange}
                    style={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 0,
                        boxShadow: '0px 0px 10px 1px #00000010',
                      },
                    }}
                  />
                )}
              </Grid>
            </Grid>

            <InputField
              multiline={true}
              rows={3}
              name="correctAnswerDescription"
              label="Description of the correct response"
              value={selectedQuestion?.correctAnswerDescription || ''}
              required={true}
              fullWidth={true}
              onChange={handleChange}
              style={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                  boxShadow: '0px 0px 10px 1px #00000010',
                },
              }}
            />

            <Box>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Button
                    variant="text"
                    disabled={selectedQuestionIndex === 0}
                    sx={{
                      mt: 3,
                      display:
                        selectedQuestion?.id || selectedQuestion?.id === 0
                          ? 'initial'
                          : 'none',
                    }}
                    onClick={showPreviousQuestion}
                  >
                    {'<'} Previous
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={7}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="info"
                    disabled={!isFormDirty}
                    sx={{ mt: 3 }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{
                      mt: 3,
                      ml: 3,
                      display:
                        selectedQuestion?.id || selectedQuestion?.id === 0
                          ? 'initial'
                          : 'none',
                    }}
                    onClick={() => setShowDeleteDialog(true)}
                  >
                    Delete
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="text"
                    disabled={
                      selectedQuestionIndex === questionsData?.length - 1
                    }
                    sx={{
                      mt: 3,
                      display:
                        selectedQuestion?.id || selectedQuestion?.id === 0
                          ? 'initial'
                          : 'none',
                    }}
                    onClick={showNextQuestion}
                  >
                    Next {'>'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>

      <CustomizedDialog
        isOpen={showDeleteDialog}
        title="Are you sure that you want to delete"
        actions={[
          {
            label: 'Cancel',
            onClick: () => setShowDeleteDialog(false),
            variant: 'contained',
          },
          { label: 'Delete', onClick: handleDelete },
        ]}
        toggle={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
