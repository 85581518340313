import axios from 'axios';

const getHeaders = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getAll = async () => {
  const token = localStorage.getItem('access_token');

  const { data } = await axios.get(
    `${process.env.REACT_APP_PURCHASED_API_PATH}/all`,
    getHeaders(token)
  );

  return data.items;
};

export const getAllStarted = async () => {
  const token = localStorage.getItem('access_token');

  const allStarted = await axios.get(
    `${process.env.REACT_APP_RUNNING_API_PATH}/all`,
    getHeaders(token)
  );

  return allStarted?.data?.results || [];
};

export const saveProgress = async (dataToSave) => {
  const token = localStorage.getItem('access_token');

  if (dataToSave?.resultId) {
    return await axios.put(
      `${process.env.REACT_APP_RUNNING_API_PATH}/go`,
      dataToSave,
      getHeaders(token)
    );
  } else {
    return await axios.post(
      `${process.env.REACT_APP_RUNNING_API_PATH}/go`,
      dataToSave,
      getHeaders(token)
    );
  }
};

export const getPreviousAttemptsForCurrentYear = async (questionnaireId) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.get(
    `https://uoecqjjrh5.execute-api.eu-west-2.amazonaws.com/prod1/runningthemes/usertheme/report?fromMonth=1&toMonth=12&year=2023&questionnaireId=${questionnaireId}`,
    getHeaders(token)
  );

  return result?.data?.results;
};

export const savePurchased = async (data) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.post(
    `${process.env.REACT_APP_PURCHASED_API_PATH}/go`,
    { ...data, status: 'purchased' },
    getHeaders(token)
  );

  return result.data;
};

export const editPurchased = async (data) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.put(
    `${process.env.REACT_APP_PURCHASED_API_PATH}/go`,
    { ...data, status: 'purchased' },
    getHeaders(token)
  );

  return result.data;
};

export const deletePurchased = async (creationTime) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.delete(
    `${process.env.REACT_APP_PURCHASED_API_PATH}/go`,
    {
      data: {
        creationTime: creationTime,
      },
    },
    getHeaders(token)
  );

  return result.data;
};

export const getSinglePurchased = async (creationTime) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.get(
    `${process.env.REACT_APP_PURCHASED_API_PATH}/go?creationtime=${creationTime}`,
    getHeaders(token)
  );

  return result.data;
};
