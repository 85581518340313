import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import config from '../../config';
import UserContext from 'context/user/UserContext';
import CoreLayout from 'components/layouts/CoreLayout';
import ThemeContext from 'context/theme/ThemeContext';
import { getUserGroup } from 'helpers';

const { cognitoAuthUrl, cognitoClientId, redirect_uri } = config;

const Login = () => {
  const { getCredentialsFromCode, user, getUserInfo, setUserGroup } =
    useContext(UserContext);
  const { withTheme } = useContext(ThemeContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleLoginClick = () => {
    window.location.replace(
      `${cognitoAuthUrl}/login?client_id=${cognitoClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${redirect_uri}`
    );
  };

  const handleRegisterClick = () => {
    window.location.replace(
      `${cognitoAuthUrl}/signup?client_id=${cognitoClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${redirect_uri}`
    );
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  // Get accessToken from callback URL
  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      getCredentialsFromCode(code);
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      const userGroup = getUserGroup(user);
      setUserGroup(userGroup);
      // navigate(`/${userGroup}`);
      navigate(`/questionnaires`);
    }
  }, [user]);

  return (
    <CoreLayout>
      <Box style={{ marginTop: 82 }}>
        <h2
          style={{
            padding: '10px',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Welcome to Add My Ask!
        </h2>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={handleLoginClick}
            className={`${withTheme('btn')} ${withTheme('btn-secondary')}`}
          >
            Login
          </Button>
          <Button
            sx={{ marginLeft: 16 }}
            onClick={handleRegisterClick}
            className={`${withTheme('btn')} ${withTheme('btn-secondary')}`}
          >
            Register
          </Button>
        </Box>
      </Box>
    </CoreLayout>
  );
};

export default Login;
