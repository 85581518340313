// Variables for the Reducer

export const SET_ELEMENT_VALUE = 'PUBLISHED_SET_ELEMENT_VALUE';
export const LIST_PUBLISHED_QUESTIONNAIRES = 'LIST_PUBLISHED_QUESTIONNAIRES';

export const GET_ALL_PUBLISHED_QUESTIONNAIRES_LOADING =
  'GET_ALL_PUBLISHED_QUESTIONNAIRES_LOADING';
export const GET_ALL_PUBLISHED_QUESTIONNAIRES_ERROR =
  'GET_ALL_PUBLISHED_QUESTIONNAIRES_ERROR';

export const SAVE_PUBLISHED_QUESTIONNAIRE_LOADING =
  'SAVE_PUBLISHED_QUESTIONNAIRE_LOADING';
export const SAVE_PUBLISHED_QUESTIONNAIRE_SUCCESS =
  'SAVE_PUBLISHED_QUESTIONNAIRE_SUCCESS';
export const SAVE_PUBLISHED_QUESTIONNAIRE_ERROR =
  'SAVE_PUBLISHED_QUESTIONNAIRE_ERROR';

export const EDIT_PUBLISHED_QUESTIONNAIRE_LOADING =
  'EDIT_PUBLISHED_QUESTIONNAIRE_LOADING';
export const EDIT_PUBLISHED_QUESTIONNAIRE_SUCCESS =
  'EDIT_PUBLISHED_QUESTIONNAIRE_SUCCESS';
export const EDIT_PUBLISHED_QUESTIONNAIRE_ERROR =
  'EDIT_PUBLISHED_QUESTIONNAIRE_ERROR';

export const DELETE_PUBLISHED_QUESTIONNAIRE_LOADING =
  'DELETE_PUBLISHED_QUESTIONNAIRE_LOADING';
export const DELETE_PUBLISHED_QUESTIONNAIRE_SUCCESS =
  'DELETE_PUBLISHED_QUESTIONNAIRE_SUCCESS';
export const DELETE_PUBLISHED_QUESTIONNAIRE_ERROR =
  'DELETE_PUBLISHED_QUESTIONNAIRE_ERROR';

export const GET_PUBLISHED_QUESTIONNAIRE_LOADING =
  'GET_PUBLISHED_QUESTIONNAIRE_LOADING';
export const GET_PUBLISHED_QUESTIONNAIRE_SUCCESS =
  'GET_PUBLISHED_QUESTIONNAIRE_SUCCESS';
export const GET_PUBLISHED_QUESTIONNAIRE_ERROR =
  'GET_PUBLISHED_QUESTIONNAIRE_ERROR';

export const GET_ACTIVE_QUESTIONNAIRES_LOADING =
  'GET_ACTIVE_QUESTIONNAIRES_LOADING';
export const GET_ACTIVE_QUESTIONNAIRES_SUCCESS =
  'GET_ACTIVE_QUESTIONNAIRES_SUCCESS';
export const GET_ACTIVE_QUESTIONNAIRES_ERROR =
  'GET_ACTIVE_QUESTIONNAIRES_ERROR';
