import React from 'react';
import { Grid, Box, Typography, Button, Chip } from '@mui/material';
import { formatDateFromMiliseconds } from 'helpers';

export const FieldsetBox = ({
  sizes = { xs: 12, md: 4, lg: 3 },
  themeType = 'Questionnaire',
  color = 'black',
  height = '150px',
  given_name = '',
  infodata = {},
  badge = '',
  buttonLabel = 'View',
  secondaryButtonLabel = '',
  onSecondaryButtonClick = () => {},
  onButtonClick = () => {},
}) => {
  const getBgColor = () => {
    switch (themeType) {
      case 'Questionnaire':
        return '#f0defd';

      case 'Survey':
        return '#def3fd';

      case 'Timed Exam':
        return '#defde0';

      case 'Manual Questionnaire':
        return '#fcf7de';
    }
  };

  return (
    <>
      <Grid item {...sizes} sx={{ marginBottom: '40px' }}>
        <Grid
          item
          sx={{
            maxWidth: { xs: '230px', lg: '230px' },
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <fieldset style={{ backgroundColor: getBgColor() }}>
            <legend
              style={{
                fontSize: '12px',
                backgroundColor: 'white',
                borderRadius: '20px',
              }}
            >
              {/* Label */}
            </legend>
            <Box
              sx={{
                color,
                height,
                p: '15px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              <Box sx={{ display: 'inherit' }}>
                {badge ? (
                  <Box sx={{}}>
                    <Chip
                      label={badge}
                      color="warning"
                      variant="outlined"
                      size="small"
                      sx={{ fontSize: '10px' }}
                    />
                  </Box>
                ) : null}
                {secondaryButtonLabel && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={onSecondaryButtonClick}
                    sx={{
                      width: 'max-content',
                      marginLeft: 'auto',
                      fontSize: '10px',
                      display: 'inline',
                    }}
                  >
                    {secondaryButtonLabel}
                  </Button>
                )}
                <Button
                  variant="contained"
                  size="small"
                  onClick={onButtonClick}
                  sx={{
                    backgroundColor: '#655D8A',
                    width: 'max-content',
                    marginLeft: 'auto',
                    fontSize: '10px',
                    display: 'inline',
                  }}
                >
                  {buttonLabel}
                </Button>
              </Box>
              <Box
                sx={{
                  height: '55%',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                }}
              >
                {infodata && infodata.themeName && (
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '700',
                      display: 'block',
                      textAlign: 'center',
                    }}
                  >
                    {infodata.themeName} v{infodata.versionNo || 0}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  height: '45%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'end',
                  position: 'relative',
                }}
              >
                <Box sx={{}}>
                  <Typography
                    sx={{
                      display: 'block',
                      mt: 3,
                      fontSize: '10px',
                      textAlign: 'end',
                    }}
                  >
                    Created by: {given_name}
                  </Typography>
                  {infodata?.creationTime && Date(infodata.creationTime) && (
                    <Typography
                      sx={{
                        display: 'block',
                        fontSize: '10px',
                        textAlign: 'end',
                      }}
                    >
                      {formatDateFromMiliseconds(infodata.creationTime)}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </fieldset>
        </Grid>
      </Grid>
    </>
  );
};
