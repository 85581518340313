import React, { useEffect, useContext } from 'react';
import UserContext from 'context/user/UserContext';
import ThemeContext from 'context/theme/ThemeContext';

export const Logout = () => {
  const { signOut } = useContext(UserContext);
  const { closeAlert } = useContext(ThemeContext);

  useEffect(() => {
    signOut();
    closeAlert();
  }, []);

  return <div>Logout</div>;
};
