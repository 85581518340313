import React from 'react';
import { Paper, InputBase, Divider, IconButton } from '@mui/material';
import { KeyboardArrowRight, Tag } from '@mui/icons-material';

export const SearchQuestionnaire = ({ onClick }) => {
  return (
    <>
      <Paper
        component="form"
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: 400,
          borderRadius: '40px',
          margin: '0 auto',
          boxShadow:
            '3px 3px 14px -2px rgba(0, 0, 0, 0.22),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        }}
      >
        <Tag sx={{ p: '10px' }} />
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
          }}
          placeholder="Enter code"
          inputProps={{ 'aria-label': 'enter code' }}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton
          type="submit"
          sx={{ p: '10px' }}
          aria-label="submit"
          onClick={onClick}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Paper>
    </>
  );
};
