import { useReducer } from 'react';
import {
  deleteDraft,
  editDraft,
  getAll,
  getSingleDraft,
  saveDraft,
} from '../../api/draft';
import DraftsContext from './DraftsContext';
import draftsReducer from './DraftsReducer';
import {
  SET_ELEMENT_VALUE,
  LIST_DRAFTS_QUESTIONNAIRES,
  GET_ALL_DRAFTS_QUESTIONNAIRES_LOADING,
  GET_ALL_DRAFTS_QUESTIONNAIRES_ERROR,
  SAVE_DRAFTS_QUESTIONNAIRE_LOADING,
  SAVE_DRAFTS_QUESTIONNAIRE_ERROR,
  SAVE_DRAFTS_QUESTIONNAIRE_SUCCESS,
  EDIT_DRAFTS_QUESTIONNAIRE_ERROR,
  EDIT_DRAFTS_QUESTIONNAIRE_LOADING,
  EDIT_DRAFTS_QUESTIONNAIRE_SUCCESS,
  DELETE_DRAFTS_QUESTIONNAIRE_LOADING,
  DELETE_DRAFTS_QUESTIONNAIRE_SUCCESS,
  DELETE_DRAFTS_QUESTIONNAIRE_ERROR,
  GET_DRAFTS_QUESTIONNAIRE_LOADING,
  GET_DRAFTS_QUESTIONNAIRE_SUCCESS,
  GET_DRAFTS_QUESTIONNAIRE_ERROR,
  SET_SELECTED_DRAFT_QUESTIONNAIRE,
} from './types';

export const initialState = {
  draft: {
    loading: false,
    error: null,
    draftQuestionnaires: [],
    selectedDraftQuestionnaire: null,
  },
};

const DraftsState = (props) => {
  // state allows us to access anything in the state and dispatch allows dispatching objects to the reducer
  // populate the purchasedReducer with the initial state to instantiate it
  const [state, dispatch] = useReducer(draftsReducer, initialState);

  const setDraftsQuestionnaires = (data) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: LIST_DRAFTS_QUESTIONNAIRES,
      payload: data,
    });
  };

  const setSelectedDraftQuestionnaire = async (data) => {
    dispatch({
      type: SET_SELECTED_DRAFT_QUESTIONNAIRE,
      payload: data,
    });
  };

  const listDraftsQuestionnaires = async () => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: GET_ALL_DRAFTS_QUESTIONNAIRES_LOADING,
      payload: true,
    });

    await getAll()
      .then((result) => {
        setDraftsQuestionnaires(result);
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_ALL_DRAFTS_QUESTIONNAIRES_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: SAVE_DRAFTS_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const saveDraftQuestionnaire = async (data) => {
    dispatch({
      type: SAVE_DRAFTS_QUESTIONNAIRE_LOADING,
      payload: true,
    });
    await saveDraft(data)
      .then((result) => {
        dispatch({
          type: SAVE_DRAFTS_QUESTIONNAIRE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SAVE_DRAFTS_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: SAVE_DRAFTS_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const editDraftsQuestionnaire = async (data) => {
    dispatch({
      type: EDIT_DRAFTS_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    await editDraft(data)
      .then((result) => {
        dispatch({
          type: EDIT_DRAFTS_QUESTIONNAIRE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: EDIT_DRAFTS_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: EDIT_DRAFTS_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const deleteDraftQuestionnaire = async (data) => {
    dispatch({
      type: DELETE_DRAFTS_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    await deleteDraft(data)
      .then(() => {
        dispatch({
          type: DELETE_DRAFTS_QUESTIONNAIRE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: DELETE_DRAFTS_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: DELETE_DRAFTS_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const getSingleDraftsQuestionnaire = async (data) => {
    dispatch({
      type: GET_DRAFTS_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    const response = await getSingleDraft(data)
      .then((result) => {
        dispatch({
          type: GET_DRAFTS_QUESTIONNAIRE_SUCCESS,
        });
        dispatch({
          type: GET_DRAFTS_QUESTIONNAIRE_LOADING,
          payload: false,
        });

        if (result?.items?.length > 0) {
          return result?.items[0];
        } else {
          console.error('no items found');
          return null;
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_DRAFTS_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      });

    return response;
  };

  const setElementValue = (name, value) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: SET_ELEMENT_VALUE,
      payload: {
        name,
        value,
      },
    });
  };

  return (
    <DraftsContext.Provider
      value={{
        state: state || {},
        setElementValue,
        listDraftsQuestionnaires,
        saveDraftQuestionnaire,
        editDraftsQuestionnaire,
        deleteDraftQuestionnaire,
        getSingleDraftsQuestionnaire,
        setSelectedDraftQuestionnaire,
      }}
    >
      {props.children}
    </DraftsContext.Provider>
  );
};

export default DraftsState;
