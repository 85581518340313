import React from 'react';
import { Paper, Box } from '@mui/material';

export const AnswerOption = ({ letter, text, isChosen, onClick }) => {
  return (
    <Paper
      component="form"
      sx={[
        {
          p: { xs: '7px 15px', md: '10px 25px' },
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          borderRadius: '40px',
          margin: { xs: '0 auto 5px auto', md: '0 auto 10px auto' },
          height: '25px',
          fontSize: { xs: '12px', sm: '14px' },
        },
        !isChosen && {
          '&:hover': {
            bgcolor: 'whitesmoke',
            cursor: 'pointer',
          },
        },
        isChosen && {
          bgcolor: 'rgba(240, 240, 197, 0.6)',
        },
      ]}
      elevation={0}
      onClick={onClick}
    >
      <Box
        sx={{
          position: 'absolute',
          left: '30px',
          top: '50%',
          transform: 'translateY(-50%)',
          fontSize: { xs: '12px', sm: '14px' },
          fontWeight: '600',
        }}
      >
        {letter}
      </Box>

      <Box sx={{ textAlign: 'center', width: '100%' }}>{text}</Box>
    </Paper>
  );
};
