import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SearchQuestionnaire } from 'components/common/SearchQuestionnaire';
import CoreLayout from 'components/layouts/CoreLayout';

export const Home = () => {
  const navigate = useNavigate();

  return (
    <CoreLayout>
      <Box height="100vh" sx={{ backgroundColor: 'white' }}>
        <Typography
          sx={{
            textAlign: 'center',
            padding: '15px',
            fontWeight: '500',
            fontSize: '19px',
            lineHeight: '24px',
            letterSpacing: '0.3px',
          }}
        >
          AddMyAsk
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            bottom: '30%',
            left: '0',
            right: '0',
            margin: 'auto',
          }}
        >
          <SearchQuestionnaire onClick={() => navigate('/question')} />
          <Box
            sx={{
              textAlign: 'center',
              mt: '14px',
              color: 'gray',
              fontSize: '12px',
              fontWeight: '600',
            }}
          >
            Agree to the terms
          </Box>
        </Box>
      </Box>
    </CoreLayout>
  );
};
