// Redux for moving attributes around with state

import {
  SET_SELECTED_QUESTION_LOADING,
  SET_SELECTED_QUESTION_SUCCESS,
  SET_SELECTED_QUESTION_ERROR,
  SET_QUESTIONNAIRE_SUCCESS,
} from './types';

/* 
  This is the Questionnaire Context reducer. 
  It accepts action type (string) and updates the store's state accordingly
*/

export default (state, action) => {
  const data = action.payload;

  switch (action.type) {
    // map all action types to corresponding action (change of state)

    case SET_SELECTED_QUESTION_LOADING:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          loading: true,
        },
      };

    case SET_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questionnaireData: data,
          loading: false,
        },
      };

    case SET_SELECTED_QUESTION_SUCCESS:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          selectedQuestion: data,
          loading: false,
        },
      };

    case SET_SELECTED_QUESTION_ERROR:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          loading: false,
          error: data,
        },
      };

    // if no case is adequate to the action type, then the previous state is returned with no modifications done
    default:
      return state;
  }
};
