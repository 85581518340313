import { useReducer } from 'react';
import {
  deleteArchive,
  editArchive,
  getAll,
  getSingleArchive,
  saveArchive,
} from '../../api/archive';
import ArchivedContext from './ArchivedContext';
import archivedReducer from './ArchivedReducer';
import {
  SET_ELEMENT_VALUE,
  LIST_ARCHIVED_QUESTIONNAIRES,
  GET_ALL_ARCHIVED_QUESTIONNAIRES_LOADING,
  GET_ALL_ARCHIVED_QUESTIONNAIRES_ERROR,
  SAVE_ARCHIVED_QUESTIONNAIRE_LOADING,
  SAVE_ARCHIVED_QUESTIONNAIRE_ERROR,
  SAVE_ARCHIVED_QUESTIONNAIRE_SUCCESS,
  EDIT_ARCHIVED_QUESTIONNAIRE_ERROR,
  EDIT_ARCHIVED_QUESTIONNAIRE_LOADING,
  EDIT_ARCHIVED_QUESTIONNAIRE_SUCCESS,
  DELETE_ARCHIVED_QUESTIONNAIRE_LOADING,
  DELETE_ARCHIVED_QUESTIONNAIRE_SUCCESS,
  DELETE_ARCHIVED_QUESTIONNAIRE_ERROR,
  GET_ARCHIVED_QUESTIONNAIRE_LOADING,
  GET_ARCHIVED_QUESTIONNAIRE_SUCCESS,
  GET_ARCHIVED_QUESTIONNAIRE_ERROR,
} from './types';

export const initialState = {
  archived: {
    loading: false,
    error: null,
    archivedQuestionnaires: [],
  },
};

const ArchivedState = (props) => {
  // state allows us to access anything in the state and dispatch allows dispatching objects to the reducer
  // populate the archivedReducer with the initial state to instantiate it
  const [state, dispatch] = useReducer(archivedReducer, initialState);

  const setArchivedQuestionnaires = (data) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: LIST_ARCHIVED_QUESTIONNAIRES,
      payload: data,
    });
  };

  const listArchivedQuestionnaires = async () => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: GET_ALL_ARCHIVED_QUESTIONNAIRES_LOADING,
      payload: true,
    });

    await getAll()
      .then((result) => {
        setArchivedQuestionnaires(result);
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_ALL_ARCHIVED_QUESTIONNAIRES_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: SAVE_ARCHIVED_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const saveArchivedQuestionnaire = async (data) => {
    dispatch({
      type: SAVE_ARCHIVED_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    await saveArchive(data)
      .then((result) => {
        dispatch({
          type: SAVE_ARCHIVED_QUESTIONNAIRE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SAVE_ARCHIVED_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: SAVE_ARCHIVED_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const editArchivedQuestionnaire = async (data) => {
    dispatch({
      type: EDIT_ARCHIVED_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    await editArchive(data)
      .then((result) => {
        dispatch({
          type: EDIT_ARCHIVED_QUESTIONNAIRE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: EDIT_ARCHIVED_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: EDIT_ARCHIVED_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const deleteArchivedQuestionnaire = async (data) => {
    dispatch({
      type: DELETE_ARCHIVED_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    await deleteArchive(data)
      .then((result) => {
        dispatch({
          type: DELETE_ARCHIVED_QUESTIONNAIRE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: DELETE_ARCHIVED_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: DELETE_ARCHIVED_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const getSingleArchivedQuestionnaire = async (data) => {
    dispatch({
      type: GET_ARCHIVED_QUESTIONNAIRE_LOADING,
      payload: true,
    });

    await getSingleArchive(data)
      .then((result) => {
        dispatch({
          type: GET_ARCHIVED_QUESTIONNAIRE_SUCCESS,
          payload: result.body,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_ARCHIVED_QUESTIONNAIRE_ERROR,
          payload: error,
        });
      })
      .then(() =>
        dispatch({
          type: GET_ARCHIVED_QUESTIONNAIRE_LOADING,
          payload: false,
        })
      );
  };

  const setElementValue = (name, value) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: SET_ELEMENT_VALUE,
      payload: {
        name,
        value,
      },
    });
  };

  return (
    <ArchivedContext.Provider
      value={{
        state: state || {},
        setElementValue,
        listArchivedQuestionnaires,
        saveArchivedQuestionnaire,
        editArchivedQuestionnaire,
        deleteArchivedQuestionnaire,
        getSingleArchivedQuestionnaire,
      }}
    >
      {props.children}
    </ArchivedContext.Provider>
  );
};

export default ArchivedState;
