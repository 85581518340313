import axios from 'axios';

export const getAll = async () => {
  const token = localStorage.getItem('access_token');

  const { data } = await axios.get(
    `${process.env.REACT_APP_DRAFT_API_PATH}/all`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data.items;
};

// TODO: improve
// `https://egesjx3321.execute-api.eu-west-2.amazonaws.com/prod1/media01-vouch/${cognitoId}_${themeName}_${questionNo}`,
export const getImageByName = async (name) => {
  const result = await axios
    .get(
      'https://egesjx3321.execute-api.eu-west-2.amazonaws.com/prod1/media01-vouch/cognitoid_themename_unique14321.jpg',
      {
        headers: { 'Content-Type': 'image/jpeg' },
      }
    )
    .catch((error) => console.log('error:', JSON.stringify(error, null, 2)));

  return result;
};

export const saveDraft = async (data) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.post(
    `${process.env.REACT_APP_DRAFT_API_PATH}/go`,
    { ...data, status: 'draft' },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};

export const editDraft = async (data) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.put(
    `${process.env.REACT_APP_DRAFT_API_PATH}/go`,
    {
      ...data,
      status: 'draft',
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};

export const deleteDraft = async (creationTime) => {
  const token = localStorage.getItem('access_token');

  await axios.delete(
    `${process.env.REACT_APP_DRAFT_API_PATH}/go`,
    {
      data: {
        creationTime: creationTime,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getSingleDraft = async (creationTime) => {
  const token = localStorage.getItem('access_token');

  const result = await axios.get(
    `${process.env.REACT_APP_DRAFT_API_PATH}/go?creationtime=${creationTime}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};
