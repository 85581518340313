import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';
import { Box } from '@mui/material';
import ThemeState from 'context/theme/ThemeState';
import ArchivedState from 'context/archived/ArchivedState';
import PublishedState from 'context/published/PublishedState';
import PurchasedState from 'context/purchased/PurchasedState';
import DraftsState from 'context/draft/DraftsState';
import UserState from 'context/user/UserState';
import QuestionnaireState from 'context/questionnaire/QuestionnaireState';
import Login from 'components/pages/login';
import { Home } from 'components/pages/home';
import { Question } from 'components/pages/question';
import { Questionnaires } from 'components/pages/questionnaires';
import { AddQuestion } from 'components/pages/addQuestion';
import { ViewTheme } from 'components/pages/viewTheme';
import { Results } from 'components/pages/results';
import {
  Logout,
  ProtectedRoute,
  NotFound404,
  ThemeForm,
} from 'components/common';

import './themes/default/pages/home.scss';
import './themes/default/common.scss';
import './themes/default/elements.scss';
import './themes/default/nav.scss';
import './themes/default/sections.scss';
import './themes/dark/pages/home.scss';
import './themes/dark/elements-dark.scss';
import './themes/dark/sections-dark.scss';
import './themes/dark/nav-dark.scss';
import './themes/blue/pages/home.scss';
import './themes/blue/elements-blue.scss';
import './themes/blue/sections-blue.scss';
import './themes/blue/nav-blue.scss';
import Wrapper from 'components/layouts/Wrapper';

// import all scss files here

// Main component in which all of the routes are declared and are wrapped inside all of the Contexts
const App = () => {
  return (
    <Router>
      <Box className="App" sx={{ minHeight: '100vh' }}>
        <ThemeState>
          <UserState>
            <ArchivedState>
              <PublishedState>
                <PurchasedState>
                  <DraftsState>
                    <QuestionnaireState>
                      {/* Wrap routes inside all Context components */}
                      <Wrapper>
                        <Routes>
                          {/* Declare all routes allowed only for users 
                      that are logged in not depending of their user group */}
                          <Route path="/" element={<ProtectedRoute />}>
                            <Route path="/" element={<Outlet />}>
                              <Route
                                exact
                                path="questionnaires"
                                element={<Questionnaires />}
                              />
                              <Route path="404" element={<NotFound404 />} />
                            </Route>
                          </Route>

                          {/* Declare all public routes */}

                          <Route exact path="/home" element={<Home />} />

                          <Route exact path="/results" element={<Results />} />

                          <Route
                            exact
                            path="/addQuestion"
                            element={<AddQuestion />}
                          />
                          <Route
                            exact
                            path="/questionnaire/:questionnaireId"
                            element={<Question />}
                          />
                          <Route
                            exact
                            path="/theme/create"
                            element={<ThemeForm isCreateMode={true} />}
                          />
                          <Route
                            exact
                            path="/theme/:themeId/view"
                            element={<ViewTheme />}
                          />
                          <Route
                            path="/theme/:themeId/questions"
                            element={<AddQuestion />}
                          />
                          <Route
                            exact
                            path="/theme/:themeId"
                            element={<ThemeForm />}
                          />
                          <Route exact path="/login" element={<Login />} />
                          <Route exact path="/logout" element={<Logout />} />
                          <Route path="*" element={<NotFound404 />} />
                        </Routes>
                      </Wrapper>
                    </QuestionnaireState>
                  </DraftsState>
                </PurchasedState>
              </PublishedState>
            </ArchivedState>
          </UserState>
        </ThemeState>
      </Box>
    </Router>
  );
};

export default App;
