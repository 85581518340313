import React from 'react';
import { TextField } from '@mui/material';

export const InputField = ({
  name = '',
  label,
  type = 'text',
  value,
  style = {},
  rows = 1,
  minNumber = null,
  maxNumber = null,
  minRows = 1,
  maxRows = 1,
  allowDecimals = false,
  multiline = false,
  required = false,
  fullWidth = false,
  placeholder = '',
  disabled = false,
  onChange = () => {},
  ...rest
}) => {
  return (
    <TextField
      name={name}
      label={label}
      type={type}
      value={value}
      placeholder={placeholder}
      multiline={multiline}
      fullWidth={fullWidth}
      rows={rows}
      step={allowDecimals ? '0.01' : '1'}
      minRows={!rows ? minRows : undefined}
      maxRows={!rows ? maxRows : undefined}
      required={required}
      disabled={disabled}
      InputProps={{ inputProps: { min: minNumber, max: maxNumber } }}
      sx={{
        display: 'block',
        mb: 2,
        '.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderTop: '0px',
          borderRight: '0px',
          borderLeft: '0px',
          borderBottom: '1px solid #00000040 !important',
        },
        '.MuiOutlinedInput-notchedOutline legend': {
          backgroundColor: 'white',
          borderRadius: '20px',
          visibility: 'visible',
        },
        '.MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
          color: 'black',
        },
        '& .MuiOutlinedInput-input': {
          zIndex: 1,
        },
        ...style,
      }}
      onChange={(event) => {
        onChange(event.target.name, event.target.value);
      }}
      {...{ rest }}
    />
  );
};
