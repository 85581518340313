import React, { useContext, useEffect } from 'react';
import UserContext from 'context/user/UserContext';

export default function Wrapper({ children }) {
  // this is a wrapper for all components in our app
  // it is used to call getUserInfo() which checks if user is logged in
  /// if not then the user is redirected to /login
  /// if yes, but the access token is expired - it refreshes it with valid refresh token

  const { getUserInfo } = useContext(UserContext);

  useEffect(() => {
    getUserInfo();
  }, []);

  return <>{children}</>;
}
