// Redux for moving attributes around with state

import {
  SET_ELEMENT_VALUE,
  LIST_DRAFTS_QUESTIONNAIRES,
  GET_ALL_DRAFTS_QUESTIONNAIRES_LOADING,
  GET_ALL_DRAFTS_QUESTIONNAIRES_ERROR,
  SAVE_DRAFTS_QUESTIONNAIRE_LOADING,
  SAVE_DRAFTS_QUESTIONNAIRE_SUCCESS,
  SAVE_DRAFTS_QUESTIONNAIRE_ERROR,
  EDIT_DRAFTS_QUESTIONNAIRE_LOADING,
  EDIT_DRAFTS_QUESTIONNAIRE_SUCCESS,
  EDIT_DRAFTS_QUESTIONNAIRE_ERROR,
  DELETE_DRAFTS_QUESTIONNAIRE_LOADING,
  DELETE_DRAFTS_QUESTIONNAIRE_SUCCESS,
  DELETE_DRAFTS_QUESTIONNAIRE_ERROR,
  GET_DRAFTS_QUESTIONNAIRE_LOADING,
  GET_DRAFTS_QUESTIONNAIRE_SUCCESS,
  GET_DRAFTS_QUESTIONNAIRE_ERROR,
  SET_SELECTED_DRAFT_QUESTIONNAIRE,
} from './types';

/* 
  This is the draft Questionnaires' Context reducer. 
  It accepts action type (string) and updates the store's state accordingly
*/

export default (state, action) => {
  const data = action.payload;

  switch (action.type) {
    // map all action types to corresponding action (change of state)

    case SET_ELEMENT_VALUE:
      const { name, value } = data;
      return {
        ...state,
        draft: {
          ...state.draft,
          [name]: value,
        },
      };

    case LIST_DRAFTS_QUESTIONNAIRES:
      return {
        ...state,
        draft: {
          ...state.draft,
          draftQuestionnaires: data,
        },
      };

    case SET_SELECTED_DRAFT_QUESTIONNAIRE:
      return {
        ...state,
        draft: {
          ...state.draft,
          selectedDraftQuestionnaire: data,
        },
      };

    case GET_ALL_DRAFTS_QUESTIONNAIRES_LOADING:
      return {
        ...state,
        draft: {
          ...state.draft,
          loading: data,
        },
      };

    case GET_ALL_DRAFTS_QUESTIONNAIRES_ERROR:
      return {
        ...state,
        draft: {
          ...state.draft,
          error: data,
        },
      };

    case SAVE_DRAFTS_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        draft: {
          ...state.draft,
          loading: data,
        },
      };

    case SAVE_DRAFTS_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        draft: {
          ...state.draft,
          draftQuestionnaires: data,
        },
      };

    case SAVE_DRAFTS_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        draft: {
          ...state.draft,
          error: data,
        },
      };

    case EDIT_DRAFTS_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        draft: {
          ...state.draft,
          loading: data,
        },
      };

    case EDIT_DRAFTS_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        draft: {
          ...state.draft,
          draftQuestionnaires: data,
        },
      };

    case EDIT_DRAFTS_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        draft: {
          ...state.draft,
          error: data,
        },
      };

    case DELETE_DRAFTS_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        draft: {
          ...state.draft,
          loading: data,
        },
      };

    case DELETE_DRAFTS_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        draft: {
          ...state.draft,
          draftQuestionnaires: data,
        },
      };

    case DELETE_DRAFTS_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        draft: {
          ...state.draft,
          error: data,
        },
      };

    case GET_DRAFTS_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        draft: {
          ...state.draft,
          loading: data,
        },
      };

    case GET_DRAFTS_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        draft: {
          ...state.draft,
          selectedDraftQuestionnaire: data,
        },
      };

    case GET_DRAFTS_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        draft: {
          ...state.draft,
          error: data,
        },
      };

    // if no case is adequate to the action type, then the previous state is returned with no modifications done
    default:
      return state;
  }
};
