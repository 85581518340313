import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ThemeContext from 'context/theme/ThemeContext';
import UserContext from 'context/user/UserContext';
import { getUserGroup } from 'helpers.js';
import { themeOptions } from 'context/theme/ThemeState';
import { ThemeSwitcherSelect } from 'components/common';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  Grid,
} from '@mui/material';

const navbarData = {
  notAuthorised: {
    secondary: [{ label: 'Login', value: '/login' }],
  },
  noUserGroup: {
    primary: [],
    secondary: [{ label: 'Logout', value: '/logout' }],
  },
};

/* 
  This is a component that creates custom NavBar. 
  It has switcher that the user can use to change the theme of the app.  
*/

const MainNavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const { theme, setTheme, withTheme } = useContext(ThemeContext);
  const { user, getUserInfo } = useContext(UserContext);

  const [navbarLinksData, setNavbarLinksData] = useState({});
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [appName, setAppName] = useState('AddMyAsk');

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getAvatarLetter = () => {
    // get the first letter of user's name
    return user.family_name.split('')[0];
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    const userGroup = getUserGroup(user);
    setNavbarLinksData(navbarData[userGroup]);
    setIsUserAuthenticated(userGroup !== 'notAuthorised');
  }, [user]);

  return (
    <AppBar position="static" className={withTheme('primary')}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 6, display: { xs: 'none', md: 'flex' } }}
          >
            {appName}
          </Typography> */}
          <Grid item sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {navbarLinksData?.primary?.map((navLinkData, i) => (
                <MenuItem key={i} onClick={handleCloseNavMenu}>
                  <Link
                    to={navLinkData.value}
                    className={withTheme('nav-link-mobile')}
                  >
                    <Typography textAlign="center">
                      {navLinkData.label}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Grid>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: 'block' }}
          >
            {appName}
          </Typography> */}
          <Grid item sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {navbarLinksData?.primary?.map((navLinkData, i) => (
              <Link
                to={navLinkData.value}
                key={i}
                className={withTheme('nav-link')}
                sx={{ my: 2, color: 'white', display: 'block', mr: 1 }}
              >
                <Button
                  key={i}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block', mr: 1 }}
                  className={withTheme('btn-secondary')}
                >
                  {navLinkData.label}
                </Button>
              </Link>
            ))}
          </Grid>
          <Grid item sx={{ flexGrow: 0, display: 'flex' }}>
            <ThemeSwitcherSelect themeOptions={themeOptions} />

            {navbarLinksData?.secondary && (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt="Avatar"
                      src=""
                      className={withTheme('primary')}
                    >
                      {isUserAuthenticated ? getAvatarLetter() : '-'}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {navbarLinksData.secondary?.map((navLinkData, i) => (
                    <MenuItem key={i} onClick={handleCloseUserMenu}>
                      <Link to={navLinkData.value} key={i}>
                        <Button key={i} onClick={handleCloseNavMenu}>
                          {navLinkData.label}
                        </Button>
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default MainNavBar;
