import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FullscreenExit, Fullscreen, Close } from '@mui/icons-material';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const CustomDialogTitle = (props) => {
  const {
    children,
    onClose,
    isFulscreen,
    showFullScreenIcon,
    toggleFullscreen,
    ...other
  } = props;

  return (
    // This renders the title of the dialog together with needed icons
    // Possible icons:
    // - open and exit fullscreen (depends on showFullScreenIcon property)
    // - close modal (only shown if custom onClose function is sent from parent as prop)

    <DialogTitle
      sx={{ m: 0, p: 2, height: '20px', marginRight: '40px' }}
      {...other}
    >
      {/* children property can contain the title or some custom element that 
        will be rendered inside the dialog title component */}
      {children}

      {/* if the value of the property showFullScreenIcon is true, 
        then a icon that toggles fullscreen is rendered */}
      {showFullScreenIcon ? (
        <IconButton
          onClick={toggleFullscreen}
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: 'gray',
          }}
        >
          {isFulscreen ? <FullscreenExit /> : <Fullscreen />}
        </IconButton>
      ) : null}

      {/* if there is a custom method that is passed as a value of the prop onClose, 
        then a icon that closes the modal is rendered */}
      {onClose ? (
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'gray',
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const CustomizedDialog = ({
  isOpen,
  toggle,
  actions = null,
  title,
  showFullScreenIcon = false,
  children,
}) => {
  const [fullscreen, setFullscreen] = useState(false);

  return (
    <div>
      <CustomDialog
        onClose={toggle}
        open={isOpen}
        fullScreen={fullscreen}
        sx={{ padding: 3 }}
      >
        {/* dialog title */}
        <CustomDialogTitle
          onClose={toggle}
          showFullScreenIcon={showFullScreenIcon}
          isFulscreen={fullscreen}
          toggleFullscreen={() => {
            setFullscreen((prev) => !prev);
          }}
        >
          {title}
        </CustomDialogTitle>

        {/* dialog content renders the data and elements 
            that are passed to this component as children */}
        <DialogContent>{children}</DialogContent>

        {/* if array of actions is sent then it renders a button for each of the defined actions */}
        {actions && actions.length > 0 ? (
          <DialogActions>
            {actions.map((action, index) => (
              <Button
                autoFocus
                key={index}
                onClick={action.onClick}
                variant={action.variant || 'outlined'}
              >
                {action.label}
              </Button>
            ))}
          </DialogActions>
        ) : null}
      </CustomDialog>
    </div>
  );
};
