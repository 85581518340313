import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Grid, Button, Typography, CircularProgress } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FieldsetBox, PaginationComponent } from 'components/common';
import Search from 'components/common/Search';
import CustomTabs from 'components/common/Tabs';
import PublishedContext from 'context/published/PublishedContext';
import PurchasedContext from 'context/purchased/PurchasedContext';
import DraftsContext from 'context/draft/DraftsContext';
import UserContext from 'context/user/UserContext';
import { saveProgress } from 'api/purchased';
import {
  getQuestionnaireUniqueId,
  randomizeQuestionsAndAnswers,
} from 'helpers';

export const Questionnaires = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState('Authored');
  const [data, setData] = useState({
    published: {},
    purchased: {},
    draft: {},
    authored: {},
    available: {},
  });

  const {
    state: publishedState,
    listPublishedQuestionnaires,
    listAvailableQuestionnaires,
  } = useContext(PublishedContext);
  const {
    state: purchasedState,
    listPurchasedQuestionnaires,
    saveQuestionnaireProgress,
  } = useContext(PurchasedContext);
  const { state: draftsState, listDraftsQuestionnaires } =
    useContext(DraftsContext);
  const { userState } = useContext(UserContext);

  const { published } = publishedState;
  const { purchased } = purchasedState;
  const { draft } = draftsState;

  const editItem = (itemData, themeState) => {
    const uniqueThemeId = getQuestionnaireUniqueId(
      itemData.creationTime,
      itemData.userId
    );
    navigate(`/theme/${uniqueThemeId}`, {
      state: { data: itemData, themeState },
    });
  };

  const viewItem = (itemData) => {
    const uniqueThemeId = getQuestionnaireUniqueId(
      itemData.creationTime,
      itemData.userId
    );
    navigate(`/theme/${uniqueThemeId}/view`, { state: itemData });
  };

  const transformData = (array) => {
    try {
      return array?.reduce((acc, item) => {
        if (!acc[item.themeType]) {
          acc[item.themeType] = {
            label: item.themeType,
            list: [],
          };
        }
        acc[item.themeType].list.push(item);

        return acc;
      }, {});
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    listPublishedQuestionnaires();
    listPurchasedQuestionnaires();
    listDraftsQuestionnaires();
    listAvailableQuestionnaires();
  }, []);

  useEffect(() => {
    if (searchParams) {
      const selectedTab = searchParams.get('selectedTab');
      if (selectedTab) setActiveTab(selectedTab);
    }
  }, [searchParams]);

  useEffect(() => {
    // This use effect is evaluated when data in published or draft questionnaires changes
    // Then the data is updated and the new data is displayed
    if (draft?.draftQuestionnaires && published?.publishedQuestionnaires) {
      let newAuthoredData = [
        ...draft.draftQuestionnaires,
        ...published.publishedQuestionnaires,
      ];

      // filter all published questionnaires and get only the ones that are not created by the logged in user
      let available = published?.availableQuestionnaires?.filter(
        (questionnaire) =>
          userState?.user &&
          userState.user['cognito:username'] !== questionnaire?.userId
      );

      setData({
        ...data,
        authored: transformData(newAuthoredData),
        available: transformData(available),
      });
    }

    // const questionsDataInLocalStorage = localStorage.getItem('')
  }, [published, draft]);

  useEffect(() => {
    // This use effect is evaluated when data in purchased questionnaires changes
    const newData = transformData(purchased?.purchasedQuestionnaires);
    setData({ ...data, purchased: newData });
  }, [purchased]);

  // TODO: needs changes not used currently
  const onSearchChange = (query) => {
    if (!query) {
      const newData = transformData(data);
      setData(newData);
      return;
    }

    // returns whole category that contains at least one questionnaire whose content includes the searched string
    const filteredCategories = Object.values(data).filter((category) => {
      const filteredElements = category.list.filter((el) =>
        el['content']?.toLowerCase().includes(query.toLowerCase())
      );

      return filteredElements.length > 0;
    });

    // returns all matched questionnaires per category
    const filteredQuestionnaires = Object.values(data).reduce(
      (acc, category) => {
        // go through each of the categories and
        // filter all questionnaires (per category) that include the search string
        const filteredElements = category.list.filter((el) => {
          return el['content']?.toLowerCase().includes(query.toLowerCase());
        });

        if (filteredElements.length)
          // if there are questionnaires that contain the entered search string,
          // then add them as a part of the corresponding category inside the accumulator object
          acc[category.label] = {
            label: category.label,
            list: filteredElements,
          };

        // return that object of filtered questionnaires by category
        return acc;
      },
      {}
    );
    // console.log('filteredQuestionnaires:', filteredQuestionnaires);

    // setData(filteredCategories);
    setData(filteredQuestionnaires);
  };

  const initializeQuiz = (questionnaire) => {
    // called when the user starts quiz for the first time
    // or decides to start quiz from beginning
    if (!questionnaire) return;

    const randomizedQA = randomizeQuestionsAndAnswers(questionnaire);
    const questionnaireId = getQuestionnaireUniqueId(
      questionnaire.creationTime,
      questionnaire.userId
    );

    saveQuestionnaireProgress(questionnaire)
      .then((result) => {
        const resultId = result?.insertId;

        questionnaire = {
          pureData: questionnaire,
          activeQuestionIndex: 0,
          // hasProgress: true,
          completedFlag: false,
          result: 0,
          themeType: questionnaire.themeType,
          questionnaireId,
          ...(resultId && { resultId }),
          ...(questionnaire.themeType === 'Timed Exam' && { timeSpendSec: 0 }),
        };

        questionnaire.pureData.infodata.questionsList = randomizedQA;
        questionnaire.pureData.activeQuestionIndex = 0;
        questionnaire.pureData.questionnaireId = questionnaireId;

        localStorage.setItem(
          'selectedQuestionnaire',
          JSON.stringify(questionnaire)
        );
      })
      .then(() => {
        navigate(`/questionnaire/${questionnaireId}`);
      });
  };

  const handleStartQuestionnaire = (
    questionnaire,
    startFromBeginning = false
  ) => {
    localStorage.removeItem('selectedQuestionnaire');

    if (!questionnaire) return;

    const questionnaireId = getQuestionnaireUniqueId(
      questionnaire?.creationTime,
      questionnaire?.userId
    );

    if (questionnaire.hasProgress) {
      // if the questionnaire has progress
      // then we have data for its progress in questionnaireDataWithProgress in purchased state

      const progressForQuestionnaire =
        purchased?.questionnairesWithProgress.find(
          (progressData) =>
            progressData?.pureData?.questionnaireId === questionnaireId
        );

      if (startFromBeginning) {
        // if the user has progress for some unfinished quiz, but decides to start from beginning

        let dataToSave = {
          pureData: progressForQuestionnaire.pureData,
          questionnaireId: progressForQuestionnaire.pureData.questionnaireId,
          completedFlag: true,
          themeType: progressForQuestionnaire.themeType,
          result: progressForQuestionnaire.result,
          resultId: progressForQuestionnaire.resultId,
        };

        // finish questionnaire
        saveProgress(dataToSave);

        // POST new quiz data
        initializeQuiz(questionnaire);
      } else {
        // continue some unfinished quiz

        const dataToSave = {
          ...progressForQuestionnaire,
          activeQuestionIndex:
            progressForQuestionnaire?.pureData?.activeQuestionIndex,
          questionnaireId: progressForQuestionnaire?.pureData?.questionnaireId,
        };

        localStorage.setItem(
          'selectedQuestionnaire',
          JSON.stringify(dataToSave)
        );

        navigate(`/questionnaire/${questionnaireId}`);
      }
    } else {
      // if the questionnaire does not have progress (the user starts the quiz for the first time)
      initializeQuiz(questionnaire);
    }
  };

  return (
    <Box sx={{ m: '20px 30px' }}>
      <Box sx={{ width: 'max-content', display: 'block', ml: 'auto' }}>
        <Button
          variant="contained"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => navigate('/theme/create')}
          sx={{ marginBottom: '20px', backgroundColor: '#655D8A' }}
        >
          Add new questionnaire
        </Button>
        {/* <Search onChange={onSearchChange} placeholder="Search questionnaires" /> */}
      </Box>

      <CustomTabs activeTab={activeTab}>
        <CustomTabs.CustomTab
          label="Authored"
          id="tab-0"
          aria-controls="tabpanel-0"
        />
        <CustomTabs.CustomTab
          label="Available"
          id="tab-1"
          aria-controls="tabpanel-1"
        />
        <CustomTabs.CustomTab
          label="Purchased"
          id="tab-2"
          aria-controls="tabpanel-2"
        />

        <CustomTabs.CustomTabPanel>
          {draft?.loading || published?.loading ? (
            <CircularProgress sx={{ mx: 'auto', display: 'block' }} />
          ) : Object.values(data.authored).length ? (
            Object.values(data.authored).map((category, categoryIndex) => {
              return (
                <Box key={categoryIndex}>
                  <Typography
                    variant={'h5'}
                    sx={{ color: 'black', mt: 3, mb: 3 }}
                  >
                    {category.label}
                  </Typography>

                  <Grid container columnSpacing={5}>
                    {Object.values(category.list).length &&
                      Object.values(category.list).map(
                        (element, elementIndex) => {
                          return (
                            <FieldsetBox
                              key={elementIndex}
                              {...element}
                              buttonLabel={
                                element.status === 'draft' ? 'Edit' : 'View'
                              }
                              badge={
                                element.status === 'published'
                                  ? 'Published'
                                  : ''
                              }
                              onButtonClick={() => {
                                if (element.status === 'draft')
                                  editItem(element, 'draft');
                                else if (element.status === 'published')
                                  viewItem(element);
                              }}
                            />
                          );
                        }
                      )}
                  </Grid>
                </Box>
              );
            })
          ) : (
            <small>no results</small>
          )}
        </CustomTabs.CustomTabPanel>

        <CustomTabs.CustomTabPanel>
          {published?.loading ? (
            <CircularProgress sx={{ mx: 'auto', display: 'block' }} />
          ) : (
            <>
              {Object.values(data.available).length ? (
                <>
                  {Object.values(data.available).map(
                    (category, categoryIndex) => {
                      return (
                        <Box key={categoryIndex}>
                          <Typography
                            variant={'h5'}
                            sx={{ color: 'black', mt: 3, mb: 3 }}
                          >
                            {category.label}
                          </Typography>

                          <Grid container columnSpacing={5}>
                            {Object.values(category.list).length &&
                              Object.values(category.list).map(
                                (element, elementIndex) => {
                                  return (
                                    <FieldsetBox
                                      key={elementIndex}
                                      {...element}
                                      buttonLabel="View"
                                      onButtonClick={() => viewItem(element)}
                                    />
                                  );
                                }
                              )}
                          </Grid>
                        </Box>
                      );
                    }
                  )}

                  {/* TODO: Show pagination component only if there is LastEvaluatedKey */}
                  {/* <PaginationComponent
                    onChange={(_, page) =>
                      console.log('onChange of page: ', page)
                    }
                  /> */}
                </>
              ) : (
                <small>no results</small>
              )}
            </>
          )}
        </CustomTabs.CustomTabPanel>

        <CustomTabs.CustomTabPanel>
          {purchased?.loading ? (
            <CircularProgress sx={{ mx: 'auto', display: 'block' }} />
          ) : Object.values(data.purchased).length ? (
            Object.values(data.purchased).map((category, categoryIndex) => {
              return (
                <Box key={categoryIndex}>
                  <Typography
                    variant={'h5'}
                    sx={{ color: 'black', mt: 3, mb: 3 }}
                  >
                    {category.label}
                  </Typography>

                  <Grid container columnSpacing={5}>
                    {Object.values(category.list).length &&
                      Object.values(category.list).map(
                        (element, elementIndex) => {
                          const hasAlreadyStarted =
                            element && element.hasProgress;

                          let buttonLabel = 'Start';
                          let secondaryButtonLabel = '';
                          if (hasAlreadyStarted) {
                            buttonLabel = 'Continue';
                            secondaryButtonLabel = 'Start again';
                          }
                          return (
                            <FieldsetBox
                              key={elementIndex}
                              {...element}
                              buttonLabel={buttonLabel}
                              secondaryButtonLabel={secondaryButtonLabel}
                              onButtonClick={() => {
                                handleStartQuestionnaire(element);
                              }}
                              onSecondaryButtonClick={() => {
                                handleStartQuestionnaire(element, true);
                              }}
                            />
                          );
                        }
                      )}
                  </Grid>
                </Box>
              );
            })
          ) : (
            <small>no results</small>
          )}
        </CustomTabs.CustomTabPanel>
      </CustomTabs>
    </Box>
  );
};
