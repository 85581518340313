import React, { useEffect, useState, useContext, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { InputField, DropdownComponent } from 'components/common';
import PublishedContext from 'context/published/PublishedContext';
import PurchasedContext from 'context/purchased/PurchasedContext';
import DraftsContext from 'context/draft/DraftsContext';
import ArchivedContext from 'context/archived/ArchivedContext';
import UserContext from 'context/user/UserContext';
import {
  formatDateFromMiliseconds,
  formatTimeFromMiliseconds,
  getQuestionnaireUniqueId,
} from 'helpers';

const initialData = {
  themeName: '',
  themeDescription: '',
  themeUrl: '',
  themeCost: 0,
  isPremium: false,
  themeType: 'Questionnaire',
  versionNo: 0,
};

const options = [
  'Questionnaire',
  'Manual Questionnaire',
  'Timed Exam',
  'Survey',
];

export const ThemeForm = ({ isCreateMode = false }) => {
  const [originalData, setData] = useState({ infodata: { ...initialData } });
  const [newData, setNewData] = useState({ infodata: { ...initialData } });
  const [isEditMode, setIsEditMode] = useState(!isCreateMode);
  const {
    saveDraftQuestionnaire,
    editDraftsQuestionnaire,
    deleteDraftQuestionnaire,
    getSingleDraftsQuestionnaire,
  } = useContext(DraftsContext);
  const { deletePublishedQuestionnaire, savePublishedQuestionnaire } =
    useContext(PublishedContext);
  const { deletePurchasedQuestionnaire } = useContext(PurchasedContext);
  const { saveArchivedQuestionnaire } = useContext(ArchivedContext);
  const { userGroup, getUserInfo } = useContext(UserContext);

  const formRef = useRef();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { themeId } = useParams();

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (!isCreateMode) {
      if (!state) {
        const creationTime = themeId.slice(5);
        getSingleDraftsQuestionnaire(parseInt(creationTime)).then((data) => {
          setData(data);
          setNewData(data);
        });
      } else {
        setData(state.data);
        setNewData(state.data);
      }
    }
  }, [state, themeId]);

  const onSubmit = async (action) => {
    // on submit save the questionnaire by default
    // if the action is publish, then publish it as well
    // const isFormValid = formRef.current.reportValidity();

    // if (!isFormValid) return;

    if (isEditMode) {
      await editDraftsQuestionnaire(newData.infodata);
    } else {
      await saveDraftQuestionnaire(newData.infodata);
    }

    if (action === 'publish') {
      await savePublishedQuestionnaire(newData.infodata);
    }

    navigate('/questionnaires');
  };

  const onDelete = async () => {
    switch (state?.themeState) {
      case 'purchased':
        await deletePurchasedQuestionnaire(newData.creationTime);
        break;
      case 'draft':
        await deleteDraftQuestionnaire(newData.creationTime);
        break;
      case 'published':
        await deletePublishedQuestionnaire(newData.creationTime);
        break;
    }

    await saveArchivedQuestionnaire(newData.infodata);

    navigate('/questionnaires');
  };

  const onChange = (name, value) => {
    setNewData({
      ...newData,
      infodata: { ...newData.infodata, ...{ [name]: value } },
    });
  };

  const onReset = () => {
    setNewData(originalData);
  };

  return (
    <Box sx={{ p: '30px 60px' }}>
      <Box>
        <Button variant="outlined" onClick={() => navigate('/questionnaires')}>
          Back
        </Button>
      </Box>
      <Typography variant="h5" sx={{ textAlign: 'center', mb: 5 }}>
        {isEditMode ? 'Edit' : 'Add'} theme
      </Typography>
      <form
        ref={formRef}
        style={{
          width: '400px',
          maxWidth: '80%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <InputField
          name="themeName"
          label="Theme name"
          value={newData?.infodata?.themeName || ''}
          required
          fullWidth={true}
          onChange={onChange}
          style={{ mt: 2 }}
        />
        <InputField
          name="themeDescription"
          label="Theme description"
          value={newData?.infodata?.themeDescription || ''}
          rows={3}
          multiline={true}
          required
          fullWidth={true}
          onChange={onChange}
        />
        <InputField
          name="themeUrl"
          label="Theme URL"
          required
          type="url"
          fullWidth={true}
          value={newData?.infodata?.themeUrl || ''}
          onChange={onChange}
        />
        <InputField
          name="themeCost"
          label="Theme cost"
          type="number"
          allowDecimals={true}
          minNumber={0}
          value={newData?.infodata?.themeCost || 0}
          required
          fullWidth={true}
          onChange={onChange}
        />

        <DropdownComponent
          name="themeType"
          label="Theme type"
          defaultValue={newData?.themeType}
          style={{ width: '100%', mb: 2 }}
          options={options}
          onChange={onChange}
        />

        {newData?.infodata?.themeType === 'Timed Exam' && (
          <InputField
            name="themeTimeLimit"
            label="Time limit (in minutes)"
            type="number"
            minNumber={1}
            maxNumber={1440}
            value={newData?.infodata?.themeTimeLimit || 0}
            required
            fullWidth={true}
            onChange={onChange}
          />
        )}

        {isEditMode && (
          <Box sx={{ mt: 3 }}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={() =>
                navigate(
                  `/theme/${getQuestionnaireUniqueId(
                    newData?.creationTime,
                    newData?.userId
                  )}/questions`,
                  {
                    state: { data: newData },
                  }
                )
              }
            >
              Manage Questions
            </Button>
          </Box>
        )}

        {userGroup === 'premium' && (
          <Box sx={{ mt: 1 }}>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  width: 'max-content',
                  color: '#5f5c5c',
                  fontSize: '14px',
                },
              }}
              label="Is premium"
              control={
                <Checkbox
                  name="isPremium"
                  checked={newData?.infodata?.isPremium || false}
                  onChange={(_, newValue) => {
                    onChange('isPremium', newValue);
                  }}
                />
              }
            />
          </Box>
        )}

        {isEditMode && (
          <Box sx={{ mt: 1 }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle-2"
                  sx={{ color: 'gray', fontSize: '14px' }}
                >
                  Number of questions
                </Typography>
                <Typography sx={{ mt: 0.5 }}>
                  {newData?.infodata?.questionsList?.length || 0} questions
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  variant="subtitle-2"
                  sx={{ color: 'gray', fontSize: '14px' }}
                >
                  Version Number
                </Typography>
                <Typography sx={{ mt: 0.5 }}>
                  {newData?.infodata?.versionNo || 0}
                </Typography>
              </Grid>

              {newData?.updatedTime && (
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle-2"
                    sx={{ color: 'gray', fontSize: '14px' }}
                  >
                    Date and time of update
                  </Typography>
                  <Typography sx={{ mt: 0.5 }}>
                    {formatDateFromMiliseconds(parseInt(newData?.updatedTime)) +
                      ', ' +
                      formatTimeFromMiliseconds(parseInt(newData?.updatedTime))}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mt: 5,
          }}
        >
          {isEditMode && (
            <Button type="button" onClick={onDelete} variant="text">
              Delete
            </Button>
          )}
          <Button type="button" onClick={onReset} variant="outlined">
            Reset
          </Button>
          <Button
            type="button"
            variant="contained"
            onClick={() => onSubmit('save')}
          >
            Save
          </Button>

          {isEditMode && newData?.infodata?.questionsList?.length > 0 && (
            <Button
              type="button"
              variant="contained"
              onClick={() => onSubmit('publish')}
              color="success"
            >
              Publish
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};
