import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import ThemeContext from '../../context/theme/ThemeContext';

const StyledTableCell = styled(TableCell)(({ theme = 'light' }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme = 'light' }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const CustomTable = (props) => {
  const { withTheme } = useContext(ThemeContext);
  const { rowsData, cellsLabelAndKeys } = props;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow className={withTheme('primary')}>
            {cellsLabelAndKeys.map(({ label }, i) => (
              <StyledTableCell key={i}>{label}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsData?.map((row, rowIndex) => (
            <StyledTableRow key={rowIndex}>
              {cellsLabelAndKeys?.map(({ keys }, cellIndex) => {
                if (cellIndex === 0) {
                  return (
                    <StyledTableCell component="th" scope="row" key={cellIndex}>
                      {rowIndex + 1}
                    </StyledTableCell>
                  );
                } else {
                  return (
                    <StyledTableCell align="left" key={cellIndex}>
                      {keys.map((key) => `${row[key]} `)}
                    </StyledTableCell>
                  );
                }
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
