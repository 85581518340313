import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  position: 'relative',
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  width: '100%',
};

const img = {
  display: 'block',
  height: 'auto',
  maxWidth: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  objectFit: 'contain',
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const wrapperContainer = {
  height: '125px',
  display: 'block',
  width: '125px',
  marginTop: 'auto',
  marginBottom: '16px',
  marginLeft: '18px',
};

const Dropzone = ({ handleDrop, onError }) => {
  // State containing the uploaded files
  const [files, setFiles] = useState([]);

  // Helper functions from the dropzone hook
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    // accept: 'image/*',
    maxSize: 1000000, //10MB
    onDropAccepted: (acceptedFiles) => {
      // set the uploaded files in state
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      handleDrop(acceptedFiles[0]);
    },
    onDropRejected: (files) => {
      files.forEach((fileData) => {
        fileData?.errors?.forEach((error) => {
          onError(error.message);
        });
      });
    },
  });

  // update the style whenever some of the variables in the array ([isDragActive, isDragReject, isDragAccept])
  // has changed
  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // thumbnails for each of the uploaded files (images)
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          alt="selected"
          src={file.preview}
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section style={wrapperContainer}>
      {/* if there are no uploaded files show empty input */}
      {!thumbs.length ? (
        <div {...getRootProps({ className: 'dropzone', style })}>
          <input {...getInputProps()} />
          {isDragActive ? <p>O</p> : <p>+</p>}
        </div>
      ) : (
        <aside style={thumbsContainer}>
          {/* else, if there are uploaded files show the thumbnails */}
          {thumbs}
          {/* X button that cleans up the uploaded files array */}
          <button
            onClick={() => setFiles([])}
            style={{ position: 'absolute', right: 0 }}
          >
            X
          </button>
        </aside>
      )}
    </section>
  );
};

export default Dropzone;
